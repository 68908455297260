<template>
    <div>
        <GlobalButton
            v-if="isAdmin || isManager"
            :route="'/global-items'"
            :text="$t('entities.items.globalItems')"
            icon="mdi-shape-outline"
        />
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
                :disabled="!$store.getters.arena || loading"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.items.title') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ addThousandSeparator(pagination.total) }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading || !$store.getters.arena"
                        />
                        <v-spacer></v-spacer>
                        <v-dialog
                            v-model="editDialog"
                            v-if="isAdmin || isManager"
                            max-width="1200px"
                        >
                            <v-form
                                @submit.prevent="save"
                                method="POST"
                                ref="form"
                            >
                                <v-card>
                                    <EditToolbar
                                        :formTitle="formTitle"
                                        :closeEditDialog="closeEditDialog"
                                    />
                                    <v-card-text class="px-0 pb-0">
                                        <v-container>
                                            <v-card class="px-4 pb-4">
                                                <RequiredFields/>
                                                <DialogImage
                                                    :image="editedEntity.image"
                                                    :name="editedEntity.name"
                                                />
                                                <v-row
                                                    align="center"
                                                    class="mt-2"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="py-0"
                                                    >
                                                        <v-select
                                                            :value="editedEntity.category ? editedEntity.category.id : null"
                                                            @change="updateCategory($event)"
                                                            :items="categories"
                                                            :rules="[v => !!v || $t('validation.required')]"
                                                            :menu-props="{ bottom: true, offsetY: true }"
                                                            :disabled="editedIndex !== -1"
                                                            item-text="name"
                                                            item-value="id"
                                                            class="do-not-apply-transform"
                                                            prepend-inner-icon="category"
                                                            return-object
                                                            solo
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('entities.items.chooseCategory') }}
                                                                <span :style="{ color: 'red' }">*</span>
                                                            </template>
                                                            <template v-slot:selection="data">
                                                                {{ $t('entities.categories.entity') }}: {{ data.item.name }}
                                                            </template>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative py-0"
                                                    >
                                                        <v-flex>
                                                            <ImageInput
                                                                :file.sync="file"
                                                                :alreadyHasImage="editedEntity.image"
                                                            />
                                                        </v-flex>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.name"
                                                            :label="$t('generalAttributes.name')"
                                                            prepend-icon="mdi-format-title"
                                                            disabled
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="py-0"
                                                    >
                                                        <v-combobox
                                                            :value="editedEntity.tags"
                                                            @change="v => (editedEntity.tags = v)"
                                                            :label="$t('entities.items.attributes.tags')"
                                                            append-icon=""
                                                            clearable
                                                            multiple
                                                            deletable-chips
                                                            small-chips
                                                            solo
                                                        ></v-combobox>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="py-0"
                                                    >
                                                        <v-textarea
                                                            v-model="editedEntity.description"
                                                            name="content"
                                                            :label="$t('generalAttributes.description')"
                                                            prepend-icon="mdi-text"
                                                            rows="1"
                                                            auto-grow
                                                            persistent-hint
                                                        ></v-textarea>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="py-0"
                                                    >
                                                        <v-switch
                                                            :input-value="editedEntity.active"
                                                            @change="v => (editedEntity.active = v)"
                                                            :label="$t('entities.items.attributes.active')"
                                                            prepend-icon="done"
                                                            inset
                                                        ></v-switch>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="py-0"
                                                    >
                                                        <v-switch
                                                            :input-value="editedEntity.promoted"
                                                            @change="v => (editedEntity.promoted = v)"
                                                            :label="$t('entities.items.attributes.promoted')"
                                                            prepend-icon="done"
                                                            inset
                                                        ></v-switch>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                            <v-card class="mt-4">
                                                <v-row align="center">
                                                    <v-col
                                                        cols="12"
                                                        class="py-0"
                                                    >
                                                        <template v-if="editedIndex !== -1">
                                                            <v-data-table
                                                                v-model="selectedEdit"
                                                                @click:row="clickEditRow"
                                                                @toggle-select-all="selectEditAllToggle"
                                                                :headers="variantHeaders"
                                                                :items="indexedVariants"
                                                                :expanded.sync="selectedEdit"
                                                                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                                                                :no-data-text="$t('components.noData.noEntriesFound')"
                                                                :footer-props="{ itemsPerPageText : $t('components.rowsPerPage.rows'), itemsPerPageAllText: $t('components.rowsPerPage.all') }"
                                                                class="do-not-apply-hover cursor-pointer"
                                                                show-select
                                                                dense
                                                            >
                                                                <template v-slot:top>
                                                                    <v-toolbar flat>
                                                                        <v-toolbar-title>
                                                                            <small>
                                                                                {{ $t('entities.items.variants') }}
                                                                            </small>
                                                                        </v-toolbar-title>
                                                                        <v-divider
                                                                            class="mx-4"
                                                                            inset
                                                                            vertical
                                                                        ></v-divider>
                                                                        <small class="mr-2">{{ editedEntity.variants ? editedEntity.variants.length : '' }}</small>
                                                                    </v-toolbar>
                                                                </template>
                                                                <template v-slot:item.data-table-select="{ item, isSelected, select }">
                                                                    <v-simple-checkbox
                                                                        v-if="!item.warehouses_count"
                                                                        @input="select($event)"
                                                                        :value="isSelected"
                                                                    ></v-simple-checkbox>
                                                                </template>
                                                                <template v-slot:item.image="{ item }">
                                                                    <template v-if="item.image">
                                                                        <img
                                                                            :src="item.image"
                                                                            :alt="item.name"
                                                                            width="25px"
                                                                        />
                                                                    </template>
                                                                    <template v-else>
                                                                        /
                                                                    </template>
                                                                </template>
                                                                <template v-slot:item.active="{ item }">
                                                                    <Boolean :boolean="item.active"/>
                                                                </template>
                                                                <template v-slot:item.promoted="{ item }">
                                                                    <Boolean :boolean="item.promoted"/>
                                                                </template>
                                                                <template v-slot:item.warehouses_count="{ item }">
                                                                    <Boolean :boolean="item.warehouses_count"/>
                                                                </template>
                                                                <template v-slot:expanded-item="{ item }">
                                                                    <td
                                                                        :colspan="$vuetify.breakpoint.xs ? '1' : '8'"
                                                                        class="py-2"
                                                                    >
                                                                        <v-row>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="5"
                                                                            >
                                                                                <v-text-field
                                                                                    :value="editedEntity.variants[item.index].name"
                                                                                    @change="v => (editedEntity.variants[item.index].name = v)"
                                                                                    :rules="[required]"
                                                                                    :label="$t('generalAttributes.name')"
                                                                                    prepend-icon="mdi-format-title"
                                                                                    disabled
                                                                                ></v-text-field>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="3"
                                                                            >
                                                                                <v-text-field
                                                                                    :value="editedEntity.variants[item.index].sku"
                                                                                    @change="v => (editedEntity.variants[item.index].sku = v)"
                                                                                    :rules="[required]"
                                                                                    :label="$t('entities.items.attributes.sku')"
                                                                                    prepend-icon="mdi-warehouse"
                                                                                    disabled
                                                                                >
                                                                                    <TextFieldInfoTooltip
                                                                                        :text="$t('entities.items.skuInfo')"
                                                                                        slot="append"
                                                                                    />
                                                                                </v-text-field>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="2"
                                                                            >
                                                                                <v-switch
                                                                                    :input-value="editedEntity.variants[item.index].active"
                                                                                    @change="v => (editedEntity.variants[item.index].active = v)"
                                                                                    :label="$t('entities.items.attributes.active')"
                                                                                    prepend-icon="done"
                                                                                    inset
                                                                                ></v-switch>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="2"
                                                                            >
                                                                                <v-switch
                                                                                    :input-value="editedEntity.variants[item.index].promoted"
                                                                                    @change="v => (editedEntity.variants[item.index].promoted = v)"
                                                                                    :label="$t('entities.items.attributes.promoted')"
                                                                                    prepend-icon="done"
                                                                                    inset
                                                                                ></v-switch>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="8"
                                                                                class="py-0"
                                                                            >
                                                                                <v-textarea
                                                                                    v-model="editedEntity.variants[item.index].description"
                                                                                    name="content"
                                                                                    :label="$t('generalAttributes.description')"
                                                                                    prepend-icon="mdi-text"
                                                                                    rows="1"
                                                                                    auto-grow
                                                                                    persistent-hint
                                                                                ></v-textarea>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="4"
                                                                                class="position-relative py-0"
                                                                            >
                                                                                <v-flex>
                                                                                    <ImageInput
                                                                                        :file.sync="editedEntity.variants[item.index].file"
                                                                                        :alreadyHasImage="editedEntity.variants[item.index].image"
                                                                                    />
                                                                                </v-flex>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="3"
                                                                                class="pb-0"
                                                                            >
                                                                                <v-select
                                                                                    :value="editedEntity.variants[item.index].warehouse && editedEntity.variants[item.index].warehouse.id ? editedEntity.variants[item.index].warehouse.id : null"
                                                                                    @change="updateWarehouse($event, item.index)"
                                                                                    :items="warehouses"
                                                                                    :rules="[v => !!v || $t('validation.required')]"
                                                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                                                    :no-data-text="$t('components.noData.noEntriesFound')"
                                                                                    item-text="name"
                                                                                    item-value="id"
                                                                                    class="do-not-apply-transform"
                                                                                    prepend-inner-icon="mdi-warehouse"
                                                                                    return-object
                                                                                    solo
                                                                                >
                                                                                    <template v-slot:label>
                                                                                        {{ $t('entities.items.chooseWarehouse') }}
                                                                                        <span :style="{ color: 'red' }">*</span>
                                                                                    </template>
                                                                                    <template v-slot:selection="data">
                                                                                        {{ data.item.name }}
                                                                                    </template>
                                                                                </v-select>
                                                                            </v-col>
                                                                            <template v-if="editedEntity.variants[item.index] && editedEntity.variants[item.index].warehouses[getSelectedWarehouseIndex(item.index)]">
                                                                                <v-col
                                                                                    cols="12"
                                                                                    sm="3"
                                                                                    class="pb-0"
                                                                                >
                                                                                    <v-select
                                                                                        :value="editedEntity.variants[item.index].warehouses[getSelectedWarehouseIndex(item.index)].pivot.taxes"
                                                                                        @change="v => (editedEntity.variants[item.index].warehouses[getSelectedWarehouseIndex(item.index)].pivot.taxes = v)"
                                                                                        :items="$store.getters.arena.company.taxes"
                                                                                        :rules="[v => v && !!v.length || $t('validation.required')]"
                                                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                                                        :no-data-text="$t('components.noData.noEntriesFound')"
                                                                                        item-text="name"
                                                                                        item-value="id"
                                                                                        prepend-inner-icon="library_books"
                                                                                        return-object
                                                                                        multiple
                                                                                        solo
                                                                                    >
                                                                                        <template v-slot:label>
                                                                                            {{ $t('entities.items.chooseTaxes') }}
                                                                                            <span :style="{ color: 'red' }">*</span>
                                                                                        </template>
                                                                                        <template v-slot:selection="data">
                                                                                            {{ data.item.name }}
                                                                                        </template>
                                                                                    </v-select>
                                                                                </v-col>
                                                                                <v-col
                                                                                    cols="12"
                                                                                    sm="2"
                                                                                    class="position-relative pb-0"
                                                                                >
                                                                                    <v-text-field
                                                                                        @blur="() => { return true }"
                                                                                        v-model="editedEntity.variants[item.index].warehouses[getSelectedWarehouseIndex(item.index)].pivot.price"
                                                                                        :rules="[required, minPrice]"
                                                                                        v-money="money"
                                                                                        :label="$t('generalAttributes.price')"
                                                                                        ref="price"
                                                                                        prepend-icon="mdi-cash-multiple"
                                                                                        validate-on-blur
                                                                                    ></v-text-field>
                                                                                    <span class="required-fields">*</span>
                                                                                </v-col>
                                                                                <v-col
                                                                                    cols="12"
                                                                                    sm="2"
                                                                                    class="pb-0"
                                                                                >
                                                                                    <v-text-field
                                                                                        v-model="editedEntity.variants[item.index].warehouses[getSelectedWarehouseIndex(item.index)].pivot.cost"
                                                                                        v-money="money"
                                                                                        :label="$t('generalAttributes.cost')"
                                                                                        ref="cost"
                                                                                        prepend-icon="mdi-cash"
                                                                                    ></v-text-field>
                                                                                </v-col>
                                                                                <v-col
                                                                                    cols="12"
                                                                                    sm="2"
                                                                                    class="position-relative pb-0"
                                                                                >
                                                                                    <v-text-field
                                                                                        :value="editedEntity.variants[item.index].warehouses[getSelectedWarehouseIndex(item.index)].pivot.stock"
                                                                                        @change="v => (editedEntity.variants[item.index].warehouses[getSelectedWarehouseIndex(item.index)].pivot.stock = v)"
                                                                                        :rules="[integer]"
                                                                                        :label="$t('entities.items.attributes.stock')"
                                                                                        type="number"
                                                                                        prepend-icon="mdi-stocking"
                                                                                        :disabled="!canEditStock"
                                                                                    ></v-text-field>
                                                                                    <span class="required-fields">*</span>
                                                                                </v-col>
                                                                            </template>
                                                                        </v-row>
                                                                        <v-row>
                                                                            <v-col
                                                                                cols="12"
                                                                                class="position-relative"
                                                                            >
                                                                                <span class="font-weight-bold">{{ $t('entities.items.categoryAttributes') }}</span>
                                                                            </v-col>
                                                                            <template v-if="!editedEntity.category">
                                                                                <v-col
                                                                                    cols="12"
                                                                                    class="pb-4"
                                                                                >
                                                                                    <span>{{ $t('entities.items.chooseCategoryMessage') }}</span>
                                                                                </v-col>
                                                                            </template>
                                                                            <template v-else-if="!editedEntity.category.attributes">
                                                                                <v-col
                                                                                    cols="12"
                                                                                    class="pb-4"
                                                                                >
                                                                                    <span>{{ $t('entities.items.noCategoryAttributesRequired') }}</span>
                                                                                </v-col>
                                                                            </template>
                                                                            <template v-else>
                                                                                <v-col
                                                                                    v-for="(value, attribute) in editedEntity.variants[item.index].category_attributes"
                                                                                    :key="`category-name-${attribute}`"
                                                                                    cols="12"
                                                                                >
                                                                                    <v-row align="center">
                                                                                        <v-col
                                                                                            cols="12"
                                                                                            class="position-relative pt-0 pb-4"
                                                                                        >
                                                                                            <ArbitraryInput
                                                                                                :value.sync="editedEntity.variants[item.index].category_attributes[attribute].value"
                                                                                                :type="editedEntity.variants[item.index].category_attributes[attribute].type"
                                                                                                :rules="formatRules(editedEntity.variants[item.index].category_attributes[attribute].type, editedEntity.variants[item.index].category_attributes[attribute].rules)"
                                                                                                :label="editedEntity.variants[item.index].category_attributes[attribute].name"
                                                                                                :icon="'input'"
                                                                                            />
                                                                                            <span
                                                                                                v-if="editedEntity.variants[item.index].category_attributes[attribute].rules && editedEntity.variants[item.index].category_attributes[attribute].rules.includes('required')"
                                                                                                class="required-fields"
                                                                                            >
                                                                                                *
                                                                                            </span>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </v-col>
                                                                            </template>
                                                                        </v-row>
                                                                    </td>
                                                                </template>
                                                                <template v-slot:footer.page-text="items">
                                                                    {{ items.pageStart }} - {{ items.pageStop }} {{ $t('components.pagination.of') }} {{ items.itemsLength }}
                                                                </template>
                                                            </v-data-table>
                                                        </template>
                                                        <ValidationErrors
                                                            v-if="validationErrors.length"
                                                            :errors="validationErrors"
                                                            class="mt-4"
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-container>
                                    </v-card-text>
                                    <v-divider/>
                                    <v-card-actions>
                                        <v-btn
                                            @click="closeEditDialog()"
                                            color="dark darken-1"
                                            text
                                        >
                                            {{ $t('miscellaneous.cancel') }}
                                        </v-btn>
                                        <v-spacer/>
                                        <v-btn
                                            @click="save"
                                            :disabled="(editedIndex === -1 && !editedEntity.variants.length) || saving"
                                            :loading="saving"
                                            color="primary"
                                            text
                                        >
                                            <span>{{ saving ? $t('miscellaneous.saving') : $t('miscellaneous.save') }}</span>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-form>
                        </v-dialog>
                        <v-dialog
                            v-if="isAdmin || isManager"
                            v-model="deleteDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <DeleteToolbar
                                        :text="$t('entities.items.deleteEntity')"
                                        :closeDeleteDialog="closeDeleteDialog"
                                    />
                                    <v-container>
                                        <DialogImage
                                            :image="toDeleteEntity.image"
                                            :name="toDeleteEntity.name"
                                        />
                                        <v-card>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ $t('entities.categories.entity') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ toDeleteEntity.category ? toDeleteEntity.category.name : '/'}}
                                                            </td>
                                                            <td>{{ $t('generalAttributes.name') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ toDeleteEntity.name }}
                                                            </td>
                                                            <td>{{ $t('entities.items.attributes.active') }}</td>
                                                            <td class="font-weight-bold">
                                                                <Boolean :boolean="toDeleteEntity.active"/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.description') }}</td>
                                                            <td
                                                                colspan="5"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ toDeleteEntity.description || '/' }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                        <v-card class="mt-4">
                                            <v-form
                                                @submit.prevent="deleteEntity"
                                                method="POST"
                                                ref="deleteForm"
                                            >
                                                <v-row align="center">
                                                    <v-col
                                                        cols="12"
                                                        class="py-0"
                                                    >
                                                        <v-data-table
                                                            v-model="selected"
                                                            @click:row="clickDeleteRow"
                                                            @toggle-select-all="selectDeleteAllToggle"
                                                            :headers="variantHeaders"
                                                            :items="toDeleteEntity.variants"
                                                            :expanded.sync="selected"
                                                            :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                                                            :no-data-text="$t('components.noData.noEntriesFound')"
                                                            :footer-props="{ itemsPerPageText : $t('components.rowsPerPage.rows'), itemsPerPageAllText: $t('components.rowsPerPage.all') }"
                                                            class="do-not-apply-hover cursor-pointer"
                                                            show-select
                                                            dense
                                                        >
                                                            <template v-slot:top>
                                                                <v-toolbar flat>
                                                                    <v-toolbar-title>
                                                                        <small>
                                                                            {{ $t('entities.items.variants') }}
                                                                        </small>
                                                                    </v-toolbar-title>
                                                                    <v-divider
                                                                        class="mx-4"
                                                                        inset
                                                                        vertical
                                                                    ></v-divider>
                                                                    <small class="mr-2">{{ toDeleteEntity.variants ? toDeleteEntity.variants.length : '' }}</small>
                                                                </v-toolbar>
                                                            </template>
                                                            <template v-slot:item.data-table-select="{ isSelected, select }">
                                                                <v-simple-checkbox
                                                                    @input="select($event)"
                                                                    :value="isSelected"
                                                                ></v-simple-checkbox>
                                                            </template>
                                                            <template v-slot:item.image="{ item }">
                                                                <template v-if="item.image">
                                                                    <img
                                                                        :src="item.image"
                                                                        :alt="item.name"
                                                                        width="25px"
                                                                    />
                                                                </template>
                                                                <template v-else>
                                                                    /
                                                                </template>
                                                            </template>
                                                            <template v-slot:item.description="{ item }">
                                                                {{ item.description || '/' }}
                                                            </template>
                                                            <template v-slot:item.active="{ item }">
                                                                <Boolean :boolean="item.active"/>
                                                            </template>
                                                            <template v-slot:item.promoted="{ item }">
                                                                <Boolean :boolean="item.promoted"/>
                                                            </template>
                                                            <template v-slot:expanded-item="{ item }">
                                                                <td
                                                                    :colspan="$vuetify.breakpoint.xs ? '1' : '7'"
                                                                    class="pt-2"
                                                                >
                                                                    <v-select
                                                                        :value="item.toDeleteWarehouses"
                                                                        @change="v => (item.toDeleteWarehouses = v)"
                                                                        :items="item.warehouses"
                                                                        :rules="[v => !!v.length || $t('validation.required')]"
                                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                                        item-text="name"
                                                                        item-value="id"
                                                                        class="do-not-apply-transform pt-4"
                                                                        prepend-inner-icon="mdi-warehouse"
                                                                        return-object
                                                                        multiple
                                                                        solo
                                                                    >
                                                                        <template v-slot:label>
                                                                            {{ $t('entities.items.chooseWarehouses') }}
                                                                            <span :style="{ color: 'red' }">*</span>
                                                                        </template>
                                                                        <template v-slot:selection="data">
                                                                            <v-chip
                                                                                color="primary"
                                                                                small
                                                                            >
                                                                                {{ data.item.name }}
                                                                            </v-chip>
                                                                        </template>
                                                                    </v-select>
                                                                </td>
                                                            </template>
                                                            <template v-slot:footer.page-text="items">
                                                                {{ items.pageStart }} - {{ items.pageStop }} {{ $t('components.pagination.of') }} {{ items.itemsLength }}
                                                            </template>
                                                        </v-data-table>
                                                        <ValidationErrors
                                                            v-if="validationErrors.length"
                                                            :errors="validationErrors"
                                                            class="mt-4"
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <v-divider/>
                                <v-card-actions>
                                    <v-btn
                                        @click="closeDeleteDialog()"
                                        color="dark darken-3"
                                        text
                                    >
                                        {{ $t('miscellaneous.cancel') }}
                                    </v-btn>
                                    <v-spacer/>
                                    <v-btn
                                        @click="deleteEntity"
                                        :disabled="!selected.length || deleting"
                                        :loading="deleting"
                                        color="error"
                                        text
                                    >
                                        <span>{{ deleting ? $t('miscellaneous.deleting') : $t('entities.items.removeFromSelectedWarehouses') }}</span>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.description="{ item }">
                    {{ item.description ? (item.description.length > 80 ? `${item.description.substring(0, 80)}...` : item.description) : '/' }}
                </template>
                <template v-slot:item.active="{ item }">
                    <Boolean :boolean="item.active"/>
                </template>
                <template v-slot:item.promoted="{ item }">
                    <Boolean :boolean="item.promoted"/>
                </template>
                <template v-slot:item.action="{ item }" v-if="isAdmin || isManager">
                    <EditButton
                        :text="$t('entities.items.editEntity')"
                        :item="item"
                        :openEditDialog="openEditDialog"
                    />
                    <DeleteButton
                        :text="$t('entities.items.deleteEntity')"
                        :item="item"
                        :openDeleteDialog="openDeleteDialog"
                    />
                </template>
                <template v-slot:no-data>
                    <v-row>
                        <v-col class="my-4">
                            <template v-if="$store.getters.arena">
                                 <p 
                                    v-if="typing"
                                    class="mb-0"
                                >
                                    {{ $t('components.noData.fetchingEntries') }}
                                </p>
                                <p v-else-if="!typing && pagination.search.length">{{ $t('components.noData.filtered') }}: {{ pagination.search }}</p>
                                <p 
                                    v-else
                                    class="mb-0"
                                >
                                    {{ $t('components.noData.noEntriesFound') }}
                                </p>
                                <v-btn
                                    v-if="!typing && pagination.search.length"
                                    @click="pagination.search = ''"
                                    color="primary"
                                    outlined
                                >
                                    {{ $t('components.noData.clear') }}
                                </v-btn>
                                <v-btn
                                    v-else-if="!typing && !pagination.search.length"
                                    @click="fetchEntities()"
                                    color="primary"
                                    class="mt-3"
                                    outlined
                                >
                                    {{ $t('components.noData.tryAgain') }}
                                </v-btn>
                            </template>
                            <template v-else>
                                <p class="my-4">
                                    {{ $t('entities.items.chooseArena') }}
                                </p>
                            </template>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import Boolean from '@/components/Boolean.vue';
import GlobalButton from '@/components/GlobalButton.vue';
import ImageInput from '@/components/ImageInput.vue';
import TextFieldInfoTooltip from '@/components/TextFieldInfoTooltip';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import EditButton from '@/components/crud/edit/EditButton.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import DeleteButton from '@/components/crud/delete/DeleteButton.vue';
import DeleteToolbar from '@/components/crud/delete/DeleteToolbar.vue';
import DialogImage from '@/components/DialogImage';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import ArbitraryInput from '@/components/ArbitraryInput.vue';
import Snackbar from '@/components/Snackbar.vue';
import Item from '@/models/shop/Item';
import Category from '@/models/shop/Category';
import Warehouse from '@/models/misc/Warehouse';
import Variant from '@/models/shop/Variant';
import FilesApi from "@/services/misc/FilesApi";

import currency from '@/mixins/currency';
import isRole from '@/mixins/crud/computed/isRole';
import convertDate from '@/mixins/convert-date';
import thousandSeparator from '@/mixins/thousand-separator';
import required from '@/mixins/rules/required';
import { VMoney } from 'v-money';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { showSnackbar } from "@/mixins/crud/methods/show-snackbar";
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import { deleteDialog } from '@/mixins/crud/watch/delete-dialog.js';

export default Vue.extend({
    components: {
        Boolean,
        GlobalButton,
        ImageInput,
        TextFieldInfoTooltip,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
        ValidationErrors,
        ArbitraryInput,
        RefreshButton,
        EditToolbar,
        EditButton,
        DeleteToolbar,
        DeleteButton,
        DialogImage,
        RequiredFields,
        Snackbar,
    },

    directives: { money : VMoney },

    mixins: [
        currency,
        axiosCatch,
        convertDate,
        showSnackbar,
        paginationWatch,
        deleteDialog,
        thousandSeparator,
        isRole,
        required,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        saving: false,
        deleting: false,
        typing: false,
        apiURI: process.env.VUE_APP_SHOP_BASE_URI,
        route: 'items',
        entity: 'items',
        selected: [],
        selectedEdit: [],
        entities: [],
        categories: [],
        warehouses: [],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        selectedSearchAttributes: ['Name'],
        editDialog: false,
        deleteDialog: false,
        editedIndex: -1,
        dataTypes: [
            'string',
            'boolean',
            'integer',
            'numeric',
            'currency',
            'array',
            'date',
            'datetime',
            'time',
        ],
        file: null,
        image: null,
        editedEntity: {
            id: null,
            category: {
                id: null,
                name: '',
                attributes: {},
            },
            name: '',
            active: 1,
            promoted: 0,
            description: '',
            sku: '',
            image: '',
            tags: [],
            variants: [],
        },
        defaultEntity: {
            id: null,
            category: {
                id: null,
                name: '',
                attributes: {},
            },
            name: '',
            active: 1,
            promoted: 0,
            description: '',
            sku: '',
            image: '',
            tags: [],
            variants: [],
        },
        toDeleteEntity: {
            id: null,
            category: {
                id: null,
                name: '',
                attributes: {},
            },
            name: '',
            active: '',
            promoted: 0,
            description: '',
            sku: '',
            image: '',
            tags: [],
            variants: [],
        },
        defaultVariant: {
            id: null,
            category: {
                id: null,
                name: '',
                attributes: {},
            },
            name: '',
            active: 1,
            promoted: 0,
            description: '',
            sku: '',
            image: '',
            category_attributes: [],
            inventory_attributes: [],
            custom_attributes: [],
            warehouses: []
        },
    }),

    watch: {
        editDialog(val) {
            val || this.closeEditDialog();
            if (!val) {
                this.$refs.form.resetValidation();
                this.selectedEdit = [];
                this.validationErrors = [];
            }
        },
        deleteDialog(val) {
            if (!val) {
                this.selected = [];
            }
        },
    },

    computed: {
        indexedVariants() {
            if (this.editedEntity.variants) {
                return this.editedEntity.variants
                    .filter(variant => variant.id)
                    .map((variants, index) => ({
                        ...variants,
                        index: index
                    }));

            }
            return [];
        },
        headers() {
            const headersArray = [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('generalAttributes.description'), align: 'left', value: 'description', sortable: false },
                { text: this.$t('entities.categories.entity'), align: 'left', value: 'category.name', sortable: false },
                { text: this.$t('entities.items.attributes.active'), align: 'left', value: 'active', sortable: false },
                { text: this.$t('entities.items.attributes.promoted'), align: 'left', value: 'promoted', sortable: false },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];
            if (!this.isAdmin && !this.isManager) {
                headersArray.pop();
            }
            return headersArray
        },
        variantHeaders() {
            return [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('miscellaneous.image'), align: 'left', value: 'image', sortable: false },
                { text: this.$t('entities.items.attributes.sku'), align: 'left', value: 'sku' },
                { text: this.$t('entities.items.attributes.active'), align: 'left', value: 'active' },
                { text: this.$t('entities.items.attributes.promoted'), align: 'left', value: 'promoted' },
            ];
        },
        searchAttributes() {
            return [
                { label: this.$t('generalAttributes.name'), value: 'Name' },
                { label: this.$t('entities.categories.entity'), value: 'Category' }
            ]
        },
        formTitle() {
            return this.editDialog ? (this.$t(`entities.${this.entity}.${this.editedIndex === -1 ? 'new' : 'edit'}Entity`)) : '';
        },
        minPrice() {
            return v => (v !== `0,00${this.money.suffix}`) || this.$t('validation.minimumPrice');
        },
        integer() {
            return v => (!isNaN(v) && parseInt(Number(v)) == v && !isNaN(parseInt(v, 10))) || this.$t('validation.integer');
        },
        money() {
            return {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false,
                suffix: ` ${this.$store.getters.arena.currency}`,
            }
        },
        canEditStock() {
            const user = localStorage.getItem('user');
            if (!user) {
                return false;
            }

            const roles = JSON.parse(atob(user)).roles;
            if (roles.includes('admin') || roles.includes('manager')) {
                return true;
            }

            return false;
        }
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchEntities() {
            try {
                const append = this.setParams();
                this.loading = true;
                let query = '';
                if (this.$store.getters.arena) {
                    query += `arena_id=${this.$store.getters.arena.id}`;
                }
                const response = await Item.api.get('', `?${query}${append}`);
                this.entities = response.data.data.map((ItemDTO) => {
                    const item = new Item(ItemDTO);
                    item.variants = item.variants.map((VariantDTO) => {
                        // Displayed attributes are from the first warehouse
                        const variant = new Variant(VariantDTO);
                        variant.warehouse = variant.warehouses && variant.warehouses.length ? variant.warehouses[0] : {};
                        // No more conversion of time & datetime to local
                        return variant;
                    })
                    return item;
                });
                response.data.meta.per_page = parseInt(response.data.meta.per_page);
                Object.assign(this.pagination, response.data.meta);
                if (this.editDialog) {
                    this.closeEditDialog();
                }
            } catch (e) {
                console.warn('Items API failed.');
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async fetchCategories() {
            try {
                this.loading = true;
                const response = await Category.api.get(1);
                this.categories = response.data.data.map(CategoryDTO => new Category(CategoryDTO));
            } catch (e) {
                console.warn('Categories API failed.');
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async fetchWarehouses() {
            try {
                this.loading = true;
                const response = await Warehouse.api.get(0, `&arena_id=${this.$store.getters.arena.id}`);
                this.warehouses = response.data.map(WarehouseDTO => new Warehouse(WarehouseDTO));
            } catch (e) {
                console.warn('Warehouse API failed.');
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            try {
                this.saving = true;

                if (!this.$refs.form.validate()) {
                    return;
                }

                if (this.file) {
                    await FilesApi.upload('shop', this.file)
                        .then((response) => {
                            this.file = null;
                            if (!response || response.status !== 200) {
                                this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                return;
                            }
                            this.image = response.data.link;
                        })
                        .catch(() => {
                            this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                        });
                }

                // If creating, loop, else, check if there are edited variants
                const shouldLoop = this.editedIndex === -1 ? true : this.selectedEdit.length !== 0;

                if (shouldLoop) {
                    const variantIds = this.selectedEdit.map(function (variant) {
                        return variant.id;
                    });
                    for (const i in this.editedEntity.variants) {
                        // If updating & variant not in selected edit, continue
                        if (this.editedIndex !== -1 && !variantIds.includes(this.editedEntity.variants[i].id)) {
                            continue;
                        }
                        if (this.editedEntity.variants[i].file) {
                            await FilesApi.upload('shop', this.editedEntity.variants[i].file)
                                .then((response) => {
                                    this.editedEntity.variants[i].file = null;
                                    if (!response || response.status !== 200) {
                                        this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                    }
                                    this.editedEntity.variants[i].image = response.data.link;
                                })
                                .catch(() => {
                                    this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                });
                        }
                    }
                }

                const data = {
                    arena_id: this.$store.getters.arena.id,
                    category_id: this.editedEntity.category.id,
                    name: this.editedEntity.name,
                    image: this.image || this.editedEntity.image,
                    active: this.editedEntity.active ? 1 : 0,
                    promoted: this.editedEntity.promoted ? 1 : 0,
                    updating_item: this.editedIndex !== -1 ? 1 : 0,
                };

                // If updating item
                if (this.editedIndex !== -1) {
                    data.item_id = this.editedEntity.id;
                }

                if (this.editedEntity.description) {
                    data.description = this.editedEntity.description;
                }

                if (this.editedEntity.tags && this.editedEntity.tags.length) {
                    data.tags = this.editedEntity.tags;
                }

                if (shouldLoop) {
                    const variantIds = this.selectedEdit.map(function(variant) { return variant.id; });
                    const variants = [];
                    for (const i in this.editedEntity.variants) {
                        // If updating & variant not in selected edit, continue
                        if (this.editedIndex !== -1 && !variantIds.includes(this.editedEntity.variants[i].id)) {
                            continue;
                        }

                        const formattedVariant = {};

                        if (this.editedEntity.variants[i].id) {
                            formattedVariant.id = this.editedEntity.variants[i].id;
                        }

                        formattedVariant.name = this.editedEntity.variants[i].name;
                        formattedVariant.sku = this.editedEntity.variants[i].sku;
                        formattedVariant.active = this.editedEntity.variants[i].active ? 1 : 0;
                        formattedVariant.promoted = this.editedEntity.variants[i].promoted ? 1 : 0;

                        if (this.editedEntity.variants[i].description) {
                            formattedVariant.description = this.editedEntity.variants[i].description;
                        }

                        if (this.editedEntity.variants[i].image) {
                            formattedVariant.image = this.editedEntity.variants[i].image;
                        }

                        const warehouses = [];
                        for (const j in this.editedEntity.variants[i].warehouses) {
                            const warehouse = {};
                            warehouse.id = this.editedEntity.variants[i].warehouses[j].id;
                            warehouse.price = this.unFormat(this.editedEntity.variants[i].warehouses[j].pivot.price, 0);
                            warehouse.cost = this.unFormat(this.editedEntity.variants[i].warehouses[j].pivot.cost, 0);
                            warehouse.stock = this.editedEntity.variants[i].warehouses[j].pivot.stock;
                            if (this.editedEntity.variants[i].warehouses[j].pivot.taxes && this.editedEntity.variants[i].warehouses[j].pivot.taxes.length) {
                                warehouse.taxes = [];
                                for (const k in this.editedEntity.variants[i].warehouses[j].pivot.taxes) {
                                    warehouse.taxes.push(this.editedEntity.variants[i].warehouses[j].pivot.taxes[k].id);
                                }
                            }
                            warehouses.push(warehouse);
                        }

                        formattedVariant.warehouses = warehouses;

                        if (this.editedEntity.variants[i].category_attributes && this.editedEntity.variants[i].category_attributes.length) {
                            formattedVariant.category_attributes = this.editedEntity.variants[i].category_attributes.map(ca => ({
                                name: ca.name,
                                value: this.getValue(ca)
                            }));
                        }

                        if (this.editedEntity.variants[i].inventory_attributes && this.editedEntity.variants[i].inventory_attributes.length) {
                            formattedVariant.inventory_attributes = this.editedEntity.variants[i].inventory_attributes.map(ia => ({
                                name: ia.name,
                                type: ia.type,
                                value: this.getValue(ia)
                            }));
                        }

                        if (this.editedEntity.variants[i].custom_attributes && this.editedEntity.variants[i].custom_attributes.length) {
                            formattedVariant.custom_attributes = this.editedEntity.variants[i].custom_attributes.map(ca => ({
                                name: ca.name,
                                type: ca.type,
                                value: this.getValue(ca)
                            }));
                        }

                        variants.push(formattedVariant);
                    }
                    data.variants = variants;
                }

                if (this.editedIndex > -1) {
                    await Item.api.update(this.editedEntity.id, data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.items.notifications.update'));
                            this.closeEditDialog();
                            this.file = null;
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                } else {
                    await Item.api.create(data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.items.notifications.creation'));
                            this.closeEditDialog();
                            this.file = null;
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                }
            } catch (error) {
                console.warn(`Item Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        clickEditRow(item, row) {
            if (item.warehouses.length) {
                if (row.isExpanded) {
                    const index = this.selectedEdit.findIndex(i => i === item);
                    this.selectedEdit.splice(index, 1)
                } else {
                    this.selectedEdit.push(item);
                }
            }
        },
        selectEditAllToggle(props) {
            if (this.selectedEdit.length !== this.editedEntity.variants.length) {
                this.selectedEdit = [];
                props.items.forEach(item => {
                    this.selectedEdit.push(item);
                });
            } else {
                this.selectedEdit = [];
            }
        },
        clickDeleteRow(item, row) {
            if (item.warehouses.length) {
                if (row.isExpanded) {
                    const index = this.selected.findIndex(i => i === item);
                    this.selected.splice(index, 1)
                } else {
                    this.selected.push(item);
                }
            }
        },
        selectDeleteAllToggle(props) {
            if (this.selected.length !== this.toDeleteEntity.variants.length) {
                this.selected = [];
                props.items.forEach(item => {
                    this.selected.push(item);
                });
            } else {
                this.selected = [];
            }
        },
        async deleteEntity() {
            try {
                this.deleting = true;

                if (!this.$refs.deleteForm.validate()) {
                    return;
                }

                const data = {
                    arena_id: this.$store.getters.arena.id,
                    variants: []
                };

                for (const index in this.selected) {
                    const warehouses = [];
                    for (const jindex in this.selected[index].toDeleteWarehouses) {
                        warehouses.push({id: this.selected[index].toDeleteWarehouses[jindex].id})
                    }
                    data.variants.push({
                        id: this.selected[index].id,
                        warehouses: warehouses
                    });
                }

                await Warehouse.api.detachVariants(data)
                    .then(() => {
                        this.fetchEntities();
                        this.closeDeleteDialog();
                        this.showSnackbar('success', this.$t(`entities.items.notifications.detachedVariants`));
                    }).catch(error => {
                        this.axiosCatch(error);
                    });
            } catch (error) {
                console.warn(`Items Delete API failed.`);
                console.log(error);
            } finally {
                this.deleting = false;
            }
        },
        addCustomAttributes(index) {
            if (!this.editedEntity.variants[index].custom_attributes) {
                this.$set(this.editedEntity.variants[index], 'custom_attributes', []);
            }
            // TODO => Value should be set depending on the type
            this.editedEntity.variants[index].custom_attributes.push({
                name: '',
                type: '',
                value: ''
            });
        },
        removeCustomAttribute(variantIndex, index) {
            this.editedEntity.variants[variantIndex].custom_attributes.splice(index, 1);
        },
        addInventoryAttributes(index) {
            if (!this.editedEntity.variants[index].inventory_attributes) {
                this.$set(this.editedEntity.variants[index], 'inventory_attributes', []);
            }
            // TODO => Value should be set depending on the type
            this.editedEntity.variants[index].inventory_attributes.push({
                name: '',
                type: '',
                value: ''
            });
        },
        removeInventoryAttribute(variantIndex, index) {
            this.editedEntity.variants[variantIndex].inventory_attributes.splice(index, 1);
        },
        addVariant() {
            if (this.warehouses.length) {
                const warehouse = this.warehouses[0];
                warehouse.pivot = {
                    price: 0,
                    cost: 0,
                    stock: 0,
                    taxes: [],
                }
                if (this.$store.getters.arena && this.$store.getters.arena.company.taxes && this.$store.getters.arena.company.taxes.length) {
                    warehouse.pivot.taxes.push(this.$store.getters.arena.company.taxes[0]);
                }
                const attach = JSON.parse(JSON.stringify(this.defaultVariant));
                attach.warehouse = warehouse;
                attach.warehouses.push(warehouse);
                this.editedEntity.variants.push(JSON.parse(JSON.stringify(attach)));
            }
            this.updateCategory(this.editedEntity.category, this.editedEntity.variants.length - 1);
        },
        removeVariant(index) {
            this.editedEntity.variants.splice(index, 1);
        },
        openEditDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = JSON.parse(JSON.stringify(entity));

            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }

            if (this.$refs.price && this.$refs.cost) {
                if (this.$refs.price.$el) {
                    this.$refs.price.$el.getElementsByTagName('input')[0].value = entity.warehouse.pivot.price;
                }
                if (this.$refs.cost.$el) {
                    this.$refs.cost.$el.getElementsByTagName('input')[0].value = entity.warehouse.pivot.cost;
                }
            }

            if (this.editedIndex !== -1) {
                for (const i in this.categories) {
                    if (this.editedEntity.category && this.categories[i].id === this.editedEntity.category.id) {
                        this.editedEntity.category.attributes = this.categories[i].attributes;
                        for (const j in this.categories[i].attributes) {
                            for (const l in this.editedEntity.variants) {
                                for (const k in this.editedEntity.variants[l].inventory_attributes) {
                                    if (this.editedEntity.variants[l].inventory_attributes[k].type === 'currency') {
                                        this.editedEntity.variants[l].inventory_attributes[k].value = this.format(
                                            this.editedEntity.variants[l].inventory_attributes[k].value,
                                            true,
                                            this.$store.getters.arena ? this.$store.getters.arena.currency : 'EUR'
                                        );
                                    }
                                }
                                for (const k in this.editedEntity.variants[l].custom_attributes) {
                                    if (this.editedEntity.variants[l].custom_attributes[k].type === 'currency') {
                                        this.editedEntity.variants[l].custom_attributes[k].value = this.format(
                                            this.editedEntity.variants[l].custom_attributes[k].value,
                                            true,
                                            this.$store.getters.arena ? this.$store.getters.arena.currency : 'EUR'
                                        );
                                    }
                                }
                                for (const k in this.editedEntity.variants[l].category_attributes) {
                                    if (this.editedEntity.variants[l].category_attributes[k].name === this.categories[i].attributes[j].name) {
                                        this.editedEntity.variants[l].category_attributes[k].type = this.categories[i].attributes[j].type;
                                        this.editedEntity.variants[l].category_attributes[k].rules = this.categories[i].attributes[j].rules;
                                        if (this.editedEntity.variants[l].category_attributes[k].type === 'currency') {
                                            this.editedEntity.variants[l].category_attributes[k].value = this.format(
                                                this.editedEntity.variants[l].category_attributes[k].value,
                                                true,
                                                this.$store.getters.arena ? this.$store.getters.arena.currency : 'EUR'
                                            );
                                        }
                                    }
                                }
                            }
                        }
                        break;
                    }
                }
            }
            this.editDialog = true;
        },
        closeEditDialog() {
            this.editedEntity = JSON.parse(JSON.stringify(this.defaultEntity));
            this.editedIndex = -1;
            if (this.$refs.price && this.$refs.cost) {
                if (this.$refs.price.$el) {
                    this.$refs.price.$el.getElementsByTagName('input')[0].value = '';
                }
                if (this.$refs.cost.$el) {
                    this.$refs.cost.$el.getElementsByTagName('input')[0].value = '';
                }
            }
            this.file = null;
            this.editDialog = false;
        },
        updateCategory(category, variantIndex = -1) {
            this.editedEntity.category = category;
            // Assign category attributes to editing entity
            const attributes = [];
            for (const i in this.editedEntity.category.attributes) {
                attributes.push({
                    name: this.editedEntity.category.attributes[i].name,
                    rules: this.editedEntity.category.attributes[i].rules,
                    type: this.editedEntity.category.attributes[i].type,
                    value: this.getValue(this.editedEntity.category.attributes[i], true)
                });
            }

            if (variantIndex === -1) {
                for (const i in this.editedEntity.variants) {
                    this.editedEntity.variants[i].category_attributes = JSON.parse(JSON.stringify(attributes));
                }
            } else {
                this.editedEntity.variants[variantIndex].category_attributes = JSON.parse(JSON.stringify(attributes));
            }
        },
        updateAttributeValue(value, attribute) {
            if (value === 'currency') {
                attribute.value = this.format(0, true, this.$store.getters.arena.currency);
            } else if (value === 'string' || value === 'array') {
                attribute.value = '';
            } else if (value === 'boolean') {
                attribute.value = false;
            } else if (value === 'date' || value === 'datetime' || value === 'time') {
                attribute.value = null;
            } else if (value === 'integer' || value === 'numeric') {
                attribute.value = '';
            }
            attribute.type = value;
        },
        itemHasWarehouse(newWarehouse, index) {
            for (let i = 0; i < this.editedEntity.variants[index].warehouses.length; i++) {
                if (this.editedEntity.variants[index].warehouses[i].id === newWarehouse.id) {
                    return this.editedEntity.variants[index].warehouses[i];
                }
            }
            return null;
        },
        updateWarehouse(newWarehouse, index) {
            this.editedEntity.variants[index].warehouse = newWarehouse;

            const attributes = this.editedIndex === -1 ? false : this.itemHasWarehouse(newWarehouse, index);
            if (attributes) {
                if (this.$refs.price && this.$refs.cost) {
                    this.$refs.price.$el.getElementsByTagName('input')[0].value = this.format(attributes.pivot.price, true, attributes.arena.company.country.currency);
                    this.$refs.cost.$el.getElementsByTagName('input')[0].value = this.format(attributes.pivot.cost, true, attributes.arena.company.country.currency);
                }
                this.$set(this.editedEntity.variants[index].warehouse, 'pivot', {
                    price: attributes.pivot.price,
                    cost: attributes.pivot.cost,
                    stock: attributes.pivot.stock,
                    taxes: attributes.pivot.taxes
                });
            } else {

                this.editedEntity.variants[index].warehouses.push(newWarehouse);

                if (this.$refs.price && this.$refs.cost) {
                    if (this.$refs.price.$el) {
                        this.$refs.price.$el.getElementsByTagName('input')[0].value = '';
                    }
                    if (this.$refs.cost.$el) {
                        this.$refs.cost.$el.getElementsByTagName('input')[0].value = '';
                    }
                }
                this.$set(this.editedEntity.variants[index].warehouse, 'pivot', {
                    price: 0,
                    cost: 0,
                    stock: 0,
                    taxes: []
                });
            }
        },
        openDeleteDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toDeleteEntity = {...entity};
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.toDeleteEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        formatRules(type, rules) {
            // TODO => Format type rules for string, boolean, currency, array, date, datetime & all category possible rules.
            const rls = [];
            if (type === 'integer') {
                rls.push(v  => (!isNaN(v) && parseInt(Number(v)) == v && !isNaN(parseInt(v, 10))) || this.$t('validation.integer'));
            }

            if (type === 'numeric') {
                rls.push(v  => (!isNaN(v)) || this.$t('validation.integer'));
            }

            for (const i in rules) {
                if (rules[i] === 'required') {
                    rls.push(v => !!v || this.$t('validation.required'));
                } else if (rules[i] === 'date_format:H:i') {
                    rls.push(v => !!v || this.$t('entities.categories.validationAttributes.date_format:H:i'));
                } else if (rules[i] === 'between:1,100') {
                    rls.push(v => !!v || this.$t('entities.categories.validationAttributes.between:1,100'));
                }
            }

            return rls;
        },
        getValue(attribute, valueMissing = false) {
            if (attribute.type === 'boolean') {
                if (valueMissing) {
                    return 0;
                }
                return attribute.value ? 1 : 0;

            } else if (attribute.type === 'currency') {
                // Used for inserting data when updating category
                if (valueMissing) {
                    return this.format(0, 0);
                }
                // Used for sending data
                return this.unFormat(attribute.value, 0);
            } else if (attribute.type === 'datetime' && attribute.value) {
                attribute.value = this.fromCurrentDateTimeToUtc(attribute.value);
            }

            if (valueMissing) {
                return '';
            }

            return attribute.value;
        },
        getSelectedWarehouseIndex(index) {
            return this.editedEntity.variants[index].warehouses.findIndex(w => w.id === this.editedEntity.variants[index].warehouse.id);
        },
        refresh() {
            if (this.$store.getters.arena) {
                this.fetchEntities();
                this.fetchCategories();
                this.fetchWarehouses();
            } else {
                this.initialLoad = false;
            }
        },

    }
});

</script>


<style>

.position-relative {
    position: relative;
}

.required-fields {
    position: absolute;
    right: 13px;
    top: 15px;
    color: red;
}

.v-select .v-input__slot {
    margin-bottom: 0;
}

</style>
