<template>
    <div>
        <GlobalButton
            :route="'/items'"
            :text="$t('entities.items.title')"
            icon="mdi-shape-outline"
        />
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
                :disabled="loading"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.items.globalItems') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ addThousandSeparator(pagination.total) }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                        <v-spacer></v-spacer>
                        <v-dialog
                            v-model="viewDialog"
                            max-width="1200px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <v-toolbar
                                        class="elevation-2"
                                        color="primary"
                                    >
                                        <v-toolbar-title class="ml-0">
                                            <span class="font-weight-bold white--text">{{ $t('entities.items.viewEntity') }}</span>
                                        </v-toolbar-title>
                                        <v-spacer/>
                                        <v-btn
                                            @click="viewDialog = false"
                                            icon
                                            dark
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-container>
                                        <DialogImage
                                            :image="editedEntity.image"
                                            :name="editedEntity.image"
                                        />
                                        <v-col
                                            v-if="!$store.getters.arena"
                                            cols="12"
                                            class="pa-0"
                                        >
                                            <v-alert type="info">
                                                <span class="mb-0 text--white">{{ $t('entities.items.chooseArenaToAdd', { item: editedEntity.name }) }}</span>
                                            </v-alert>
                                        </v-col>
                                        <v-card class="mt-4">
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.name') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.name }}
                                                            </td>
                                                            <td>{{ $t('generalAttributes.description') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.description ? (editedEntity.description.length > 60 ? `${editedEntity.description.substring(0, 60)}...` : editedEntity.description) : '/' }}
                                                            </td>
                                                            <td>{{ $t('entities.items.attributes.tags') }}</td>
                                                            <td class="font-weight-bold">
                                                                <template v-if="editedEntity.tags">
                                                                    <v-chip
                                                                        v-for="(tag, i) in editedEntity.tags"
                                                                        :key="'tag-' + i"
                                                                        color="primary"
                                                                        :class="{ 'ml-2' : i !== 0}"
                                                                        small
                                                                    >
                                                                        {{ tag }}
                                                                    </v-chip>
                                                                </template>
                                                                <template v-else>
                                                                    /
                                                                </template>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.categories.entity') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.category.name }}
                                                            </td>
                                                            <td>{{ $t('entities.items.attributes.active') }}</td>
                                                            <td class="font-weight-bold">
                                                                <Boolean :boolean="editedEntity.active"/>
                                                            </td>
                                                            <td>{{ $t('entities.items.attributes.promoted') }}</td>
                                                            <td class="font-weight-bold">
                                                                <Boolean :boolean="editedEntity.promoted"/>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                        <v-card class="mt-4">
                                            <v-form
                                                @submit.prevent="save"
                                                method="POST"
                                                ref="viewForm"
                                            >
                                                <v-row align="center">
                                                    <v-col
                                                        cols="12"
                                                        class="py-0"
                                                    >
                                                        <v-data-table
                                                            v-model="selected"
                                                            @click:row="clickRow"
                                                            @toggle-select-all="selectAllToggle"
                                                            :headers="variantHeaders"
                                                            :items="indexedItems"
                                                            :show-select="showForm(editedEntity.variants) && !!$store.getters.arena"
                                                            :expanded.sync="selected"
                                                            :item-class="rowClass"
                                                            :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                                                            :no-data-text="$t('components.noData.noEntriesFound')"
                                                            :footer-props="{ itemsPerPageText : $t('components.rowsPerPage.rows'), itemsPerPageAllText: $t('components.rowsPerPage.all') }"
                                                            class="do-not-apply-hover"
                                                            dense
                                                        >
                                                            <template v-slot:top>
                                                                <v-toolbar flat>
                                                                    <v-toolbar-title>
                                                                        <small>
                                                                            {{ $t('entities.items.variants') }}
                                                                        </small>
                                                                    </v-toolbar-title>
                                                                    <v-divider
                                                                        class="mx-4"
                                                                        inset
                                                                        vertical
                                                                    ></v-divider>
                                                                    <small class="mr-2">{{ editedEntity.variants ? editedEntity.variants.length : '' }}</small>
                                                                </v-toolbar>
                                                            </template>
                                                            <template v-slot:item.data-table-select="{ item, isSelected, select }">
                                                                <v-simple-checkbox
                                                                    v-if="!item.warehouses_count"
                                                                    @input="select($event)"
                                                                    :value="isSelected"
                                                                ></v-simple-checkbox>
                                                            </template>
                                                            <template v-slot:item.image="{ item }">
                                                                <template v-if="item.image">
                                                                    <img
                                                                        :src="item.image"
                                                                        :alt="item.name"
                                                                        width="25px"
                                                                    />
                                                                </template>
                                                                <template v-else>
                                                                    /
                                                                </template>
                                                            </template>
                                                            <template v-slot:item.description="{ item }">
                                                                {{ item.description ? (item.description.length > 60 ? `${item.description.substring(0, 60)}...` : item.description) : '/' }}
                                                            </template>
                                                            <template v-slot:item.active="{ item }">
                                                                <Boolean :boolean="item.active"/>
                                                            </template>
                                                            <template v-slot:item.promoted="{ item }">
                                                                <Boolean :boolean="item.promoted"/>
                                                            </template>
                                                            <template v-slot:item.warehouses_count="{ item }">
                                                                <Boolean :boolean="item.warehouses_count"/>
                                                            </template>
                                                            <template v-slot:expanded-item="{ item }">
                                                                <td
                                                                    :colspan="$vuetify.breakpoint.xs ? '1' : '8'"
                                                                    class="py-2"
                                                                >
                                                                    <v-row>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="3"
                                                                            class="pb-0"
                                                                        >
                                                                            <v-select
                                                                                :value="editedEntity.variants[item.index].warehouse"
                                                                                @change="v => (editedEntity.variants[item.index].warehouse = v)"
                                                                                :items="warehouses"
                                                                                :rules="[v => !!v || $t('validation.required')]"
                                                                                :menu-props="{ bottom: true, offsetY: true }"
                                                                                :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                                                item-text="name"
                                                                                item-value="id"
                                                                                prepend-inner-icon="mdi-warehouse"
                                                                                return-object
                                                                                solo
                                                                            >
                                                                                <template v-slot:label>
                                                                                    {{ $t('entities.items.chooseWarehouse') }}
                                                                                    <span :style="{ color: 'red' }">*</span>
                                                                                </template>
                                                                                <template v-slot:selection="data">
                                                                                    {{ data.item.name }}
                                                                                </template>
                                                                            </v-select>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="3"
                                                                            class="pb-0"
                                                                        >
                                                                            <v-select
                                                                                :value="editedEntity.variants[item.index].taxes"
                                                                                @change="v => (editedEntity.variants[item.index].taxes = v)"
                                                                                :items="$store.getters.arena ? $store.getters.arena.company.taxes : []"
                                                                                :rules="[v => v && !!v.length || $t('validation.required')]"
                                                                                :menu-props="{ bottom: true, offsetY: true }"
                                                                                :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                                                item-text="name"
                                                                                item-value="id"
                                                                                prepend-inner-icon="library_books"
                                                                                return-object
                                                                                multiple
                                                                                solo
                                                                            >
                                                                                <template v-slot:label>
                                                                                    {{ $t('entities.items.chooseTaxes') }}
                                                                                    <span :style="{ color: 'red' }">*</span>
                                                                                </template>
                                                                                <template v-slot:selection="data">
                                                                                    {{ data.item.name }}
                                                                                </template>
                                                                            </v-select>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="2"
                                                                            class="position-relative pb-0"
                                                                        >
                                                                            <v-text-field
                                                                                @blur="() => { return true }"
                                                                                v-model="editedEntity.variants[item.index].price"
                                                                                :rules="[required, minPrice]"
                                                                                v-money="money"
                                                                                :label="$t('generalAttributes.price')"
                                                                                ref="price"
                                                                                prepend-icon="mdi-cash-multiple"
                                                                                validate-on-blur
                                                                            ></v-text-field>
                                                                            <span class="required-fields">*</span>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="2"
                                                                            class="pb-0"
                                                                        >
                                                                            <v-text-field
                                                                                v-model="editedEntity.variants[item.index].cost"
                                                                                v-money="money"
                                                                                :label="$t('generalAttributes.cost')"
                                                                                ref="cost"
                                                                                prepend-icon="mdi-cash"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="2"
                                                                            class="position-relative pb-0"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.variants[item.index].stock"
                                                                                @change="v => (editedEntity.variants[item.index].stock = v)"
                                                                                :rules="[integer]"
                                                                                :label="$t('entities.items.attributes.stock')"
                                                                                type="number"
                                                                                prepend-icon="mdi-stocking"
                                                                            ></v-text-field>
                                                                            <span class="required-fields">*</span>
                                                                        </v-col>
                                                                    </v-row>
                                                                </td>
                                                            </template>
                                                            <template v-slot:footer.page-text="items">
                                                                {{ items.pageStart }} - {{ items.pageStop }} {{ $t('components.pagination.of') }} {{ items.itemsLength }}
                                                            </template>
                                                        </v-data-table>
                                                        <ValidationErrors
                                                            v-if="validationErrors.length"
                                                            :errors="validationErrors"
                                                            class="mt-4"
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <v-divider/>
                                <v-card-actions>
                                    <v-btn
                                        @click="viewDialog = false"
                                        color="dark darken-1"
                                        text
                                    >
                                        {{ $t('miscellaneous.cancel') }}
                                    </v-btn>
                                    <v-spacer/>
                                    <v-btn
                                        v-if="showForm(editedEntity.variants) && $store.getters.arena"
                                        @click="addToArena"
                                        :disabled="!selected.length || saving"
                                        :loading="saving"
                                        color="primary"
                                    >
                                        <span>{{ saving ? $t('miscellaneous.saving') : $t('miscellaneous.addToArena', { arena: $store.getters.arena.name }) }}</span>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="editDialog"
                            max-width="1200px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-if="isAdmin"
                                    v-on="on"
                                    color="primary"
                                    class="mb-2"
                                    dark
                                >
                                    {{ $t('miscellaneous.add') }}
                                </v-btn>
                            </template>
                            <v-form
                                @submit.prevent="save"
                                method="POST"
                                ref="editForm"
                            >
                                <v-card>
                                    <EditToolbar
                                        :formTitle="formTitle"
                                        :closeEditDialog="closeEditDialog"
                                    />
                                    <v-card-text class="px-0 pb-0">
                                        <v-container>
                                            <v-card class="px-4 pb-4">
                                                <RequiredFields/>
                                                <DialogImage
                                                    :image="editedEntity.image"
                                                    :name="editedEntity.name"
                                                />
                                                <v-row
                                                    align="center"
                                                    class="mt-2"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="py-0"
                                                    >
                                                        <v-select
                                                            :value="editedEntity.category ? editedEntity.category.id : null"
                                                            @change="updateCategory($event)"
                                                            :items="categories"
                                                            :rules="[v => !!v || $t('validation.required')]"
                                                            :menu-props="{ bottom: true, offsetY: true }"
                                                            item-text="name"
                                                            item-value="id"
                                                            class="do-not-apply-transform"
                                                            prepend-inner-icon="category"
                                                            return-object
                                                            solo
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('entities.items.chooseCategory') }}
                                                                <span :style="{ color: 'red' }">*</span>
                                                            </template>
                                                            <template v-slot:selection="data">
                                                                {{ $t('entities.categories.entity') }}: {{ data.item.name }}
                                                            </template>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative py-0"
                                                    >
                                                        <v-flex>
                                                            <ImageInput
                                                                :file.sync="file"
                                                                :alreadyHasImage="editedEntity.image"
                                                            />
                                                        </v-flex>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative py-0"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.name"
                                                            @change="v => (editedEntity.name = v)"
                                                            :rules="[required]"
                                                            :label="$t('generalAttributes.name')"
                                                            prepend-icon="mdi-format-title"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="py-0"
                                                    >
                                                        <v-combobox
                                                            :value="editedEntity.tags"
                                                            @change="v => (editedEntity.tags = v)"
                                                            :label="$t('entities.items.attributes.tags')"
                                                            deletable-chips
                                                            small-chips
                                                            clearable
                                                            multiple
                                                            solo
                                                        ></v-combobox>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="py-0"
                                                    >
                                                        <v-textarea
                                                            v-model="editedEntity.description"
                                                            name="content"
                                                            :label="$t('generalAttributes.description')"
                                                            prepend-icon="mdi-text"
                                                            rows="1"
                                                            auto-grow
                                                            persistent-hint
                                                        ></v-textarea>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="py-0"
                                                    >
                                                        <v-switch
                                                            :input-value="editedEntity.active"
                                                            @change="v => (editedEntity.active = v)"
                                                            :label="$t('entities.items.attributes.active')"
                                                            prepend-icon="done"
                                                            inset
                                                        ></v-switch>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="py-0"
                                                    >
                                                        <v-switch
                                                            :input-value="editedEntity.promoted"
                                                            @change="v => (editedEntity.promoted = v)"
                                                            :label="$t('entities.items.attributes.promoted')"
                                                            prepend-icon="done"
                                                            inset
                                                        ></v-switch>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-3"
                                                    >
                                                        <v-expansion-panels
                                                            :value="editDialog"
                                                            flat
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="pr-4 grey-border">
                                                                    <h4 class="pl-4">
                                                                        {{ $t('entities.items.attributes.reviews.title') }}
                                                                    </h4>
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="grey-border pt-4">
                                                                    <template v-if="editedEntity.reviews && editedEntity.reviews.length">
                                                                        <v-row
                                                                            v-for="index in editedEntity.reviews.length"
                                                                            :key="'review-' + index"
                                                                            align="center"
                                                                            class="px-3"
                                                                        >
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="3"
                                                                                class="position-relative px-2"
                                                                            >
                                                                                <v-text-field
                                                                                    :value="editedEntity.reviews[index - 1].username"
                                                                                    @change="v => (editedEntity.reviews[index - 1].username = v)"
                                                                                    :rules="[required]"
                                                                                    :label="$t('entities.users.attributes.username')"
                                                                                    prepend-icon="mdi-account-box"
                                                                                ></v-text-field>
                                                                                <span class="required-fields">*</span>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="2"
                                                                                class="position-relative px-2"
                                                                            >
                                                                                <v-text-field
                                                                                    :value="editedEntity.reviews[index - 1].location"
                                                                                    @change="v => (editedEntity.reviews[index - 1].location = v)"
                                                                                    :label="$t('entities.items.attributes.reviews.location')"
                                                                                    prepend-icon="location_on"
                                                                                ></v-text-field>
                                                                                <span class="required-fields">*</span>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="6"
                                                                                class="position-relative px-2"
                                                                            >
                                                                                <v-text-field
                                                                                    :value="editedEntity.reviews[index - 1].text"
                                                                                    @change="v => (editedEntity.reviews[index - 1].text = v)"
                                                                                    :label="$t('entities.items.attributes.reviews.text')"
                                                                                    prepend-icon="mdi-text"
                                                                                ></v-text-field>
                                                                                <span class="required-fields">*</span>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="1"
                                                                                class="px-2"
                                                                            >
                                                                                <v-btn
                                                                                    @click="editedEntity.reviews.splice(index - 1, 1)"
                                                                                    color="error"
                                                                                    rounded
                                                                                >
                                                                                    X
                                                                                </v-btn>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </template>
                                                                    <template v-if="editedIndex !== -1 && (!editedEntity.reviews || !editedEntity.reviews.length)">
                                                                        <p class="pl-4 pt-4 pb-0">{{ $t('entities.items.attributes.reviews.notFound') }}</p>
                                                                    </template>
                                                                    <v-row v-if="editedIndex !== -1">
                                                                        <v-col class="text-center mb-2 pt-0">
                                                                            <v-btn
                                                                                @click="editedEntity.reviews.push({ username: '', text: '', location: '', avatar: null })"
                                                                                color="primary"
                                                                                class="px-12"
                                                                                rounded
                                                                            >
                                                                                {{ $t('entities.items.attributes.reviews.addNew') }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row v-else>
                                                                        <v-col class="pt-6">
                                                                            <p class="pl-4">{{ $t('entities.items.attributes.reviews.canBeAdded') }}</p>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-3"
                                                    >
                                                        <v-expansion-panels
                                                            :value="editDialog"
                                                            flat
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="pr-4 grey-border">
                                                                    <h4 class="pl-4">
                                                                        {{ $t('entities.items.attributes.shopDetails') }}
                                                                    </h4>
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="grey-border pt-4">
                                                                    <template v-if="editedEntity.shop_details">
                                                                        <v-row
                                                                            align="center"
                                                                            class="px-3"
                                                                        >
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="3"
                                                                                class="position-relative px-2"
                                                                            >
                                                                                <v-text-field
                                                                                    :value="editedEntity.shop_details.title"
                                                                                    @change="v => (editedEntity.shop_details.title = v)"
                                                                                    :label="$t('entities.events.attributes.title')"
                                                                                    prepend-icon="mdi-format-title"
                                                                                ></v-text-field>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="6"
                                                                                class="position-relative px-2"
                                                                            >
                                                                                <v-text-field
                                                                                    :value="editedEntity.shop_details.subtitle"
                                                                                    @change="v => (editedEntity.shop_details.subtitle = v)"
                                                                                    :label="$t('entities.events.attributes.subtitle')"
                                                                                    prepend-icon="mdi-text"
                                                                                ></v-text-field>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="3"
                                                                                class="position-relative px-2"
                                                                            >
                                                                                <v-text-field
                                                                                    :value="editedEntity.shop_details.image"
                                                                                    @change="v => (editedEntity.shop_details.image = v)"
                                                                                    :label="$t('miscellaneous.image')"
                                                                                    prepend-icon="image"
                                                                                ></v-text-field>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </template>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                            <v-card class="mt-4">
                                                <v-row align="center">
                                                    <v-col
                                                        v-if="editDialog"
                                                        cols="12"
                                                        class="py-0"
                                                    >
                                                        <template v-if="editedIndex !== -1">
                                                            <v-data-table
                                                                v-model="selected"
                                                                @click:row="clickEditRow"
                                                                @toggle-select-all="selectEditAllToggle"
                                                                :headers="variantHeaders"
                                                                :items="indexedVariants"
                                                                :expanded.sync="selected"
                                                                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                                                                :no-data-text="$t('components.noData.noEntriesFound')"
                                                                :footer-props="{ itemsPerPageText : $t('components.rowsPerPage.rows'), itemsPerPageAllText: $t('components.rowsPerPage.all') }"
                                                                class="do-not-apply-hover cursor-pointer"
                                                                show-select
                                                                dense
                                                            >
                                                                <template v-slot:top>
                                                                    <v-toolbar flat>
                                                                        <v-toolbar-title>
                                                                            <small>
                                                                                {{ $t('entities.items.variants') }}
                                                                            </small>
                                                                        </v-toolbar-title>
                                                                        <v-divider
                                                                            class="mx-4"
                                                                            inset
                                                                            vertical
                                                                        ></v-divider>
                                                                        <small class="mr-2">{{ editedEntity.variants ? editedEntity.variants.length : '' }}</small>
                                                                    </v-toolbar>
                                                                </template>
                                                                <template v-slot:item.data-table-select="{ item, isSelected, select }">
                                                                    <v-simple-checkbox
                                                                        @input="select($event)"
                                                                        :value="isSelected"
                                                                    ></v-simple-checkbox>
                                                                </template>
                                                                <template v-slot:item.image="{ item }">
                                                                    <template v-if="item.image">
                                                                        <img
                                                                            :src="item.image"
                                                                            :alt="item.name"
                                                                            width="25px"
                                                                        />
                                                                    </template>
                                                                    <template v-else>
                                                                        /
                                                                    </template>
                                                                </template>
                                                                <template v-slot:item.active="{ item }">
                                                                    <Boolean :boolean="item.active"/>
                                                                </template>
                                                                <template v-slot:item.promoted="{ item }">
                                                                    <Boolean :boolean="item.promoted"/>
                                                                </template>
                                                                <template v-slot:item.warehouses_count="{ item }">
                                                                    <Boolean :boolean="item.warehouses_count"/>
                                                                </template>
                                                                <template v-slot:expanded-item="{ item }">
                                                                    <td
                                                                        :colspan="$vuetify.breakpoint.xs ? '1' : '8'"
                                                                        class="py-2"
                                                                    >
                                                                        <v-row>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="5"
                                                                                class="position-relative"
                                                                            >
                                                                                <v-text-field
                                                                                    :value="editedEntity.variants[item.index].name"
                                                                                    @change="v => (editedEntity.variants[item.index].name = v)"
                                                                                    :rules="[required]"
                                                                                    :label="$t('generalAttributes.name')"
                                                                                    prepend-icon="mdi-format-title"
                                                                                ></v-text-field>
                                                                                <span class="required-fields">*</span>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="3"
                                                                                class="position-relative"
                                                                            >
                                                                                <v-text-field
                                                                                    :value="editedEntity.variants[item.index].sku"
                                                                                    @change="v => (editedEntity.variants[item.index].sku = v)"
                                                                                    :rules="[required]"
                                                                                    :label="$t('entities.items.attributes.sku')"
                                                                                    prepend-icon="mdi-warehouse"
                                                                                >
                                                                                    <TextFieldInfoTooltip
                                                                                        :text="$t('entities.items.skuInfo')"
                                                                                        slot="append"
                                                                                    />
                                                                                </v-text-field>
                                                                                <span class="required-fields">*</span>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="2"
                                                                            >
                                                                                <v-switch
                                                                                    :input-value="editedEntity.variants[item.index].active"
                                                                                    @change="v => (editedEntity.variants[item.index].active = v)"
                                                                                    :label="$t('entities.items.attributes.active')"
                                                                                    prepend-icon="done"
                                                                                    inset
                                                                                ></v-switch>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="2"
                                                                            >
                                                                                <v-switch
                                                                                    :input-value="editedEntity.variants[item.index].promoted"
                                                                                    @change="v => (editedEntity.variants[item.index].promoted = v)"
                                                                                    :label="$t('entities.items.attributes.promoted')"
                                                                                    prepend-icon="done"
                                                                                    inset
                                                                                ></v-switch>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="8"
                                                                                class="py-0"
                                                                            >
                                                                                <v-textarea
                                                                                    v-model="editedEntity.variants[item.index].description"
                                                                                    name="content"
                                                                                    :label="$t('generalAttributes.description')"
                                                                                    prepend-icon="mdi-text"
                                                                                    rows="1"
                                                                                    auto-grow
                                                                                    persistent-hint
                                                                                ></v-textarea>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="4"
                                                                                class="position-relative py-0"
                                                                            >
                                                                                <v-flex>
                                                                                    <ImageInput
                                                                                        :file.sync="editedEntity.variants[item.index].file"
                                                                                        :alreadyHasImage="editedEntity.variants[item.index].image"
                                                                                    />
                                                                                </v-flex>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row>
                                                                            <v-col
                                                                                cols="12"
                                                                                class="position-relative"
                                                                            >
                                                                                <span class="font-weight-bold">{{ $t('entities.items.categoryAttributes') }}</span>
                                                                            </v-col>
                                                                            <template v-if="!editedEntity.category">
                                                                                <v-col
                                                                                    cols="12"
                                                                                    class="pb-4"
                                                                                >
                                                                                    <span>{{ $t('entities.items.chooseCategoryMessage') }}</span>
                                                                                </v-col>
                                                                            </template>
                                                                            <template v-else-if="!editedEntity.category.attributes">
                                                                                <v-col
                                                                                    cols="12"
                                                                                    class="pb-4"
                                                                                >
                                                                                    <span>{{ $t('entities.items.noCategoryAttributesRequired') }}</span>
                                                                                </v-col>
                                                                            </template>
                                                                            <template v-else>
                                                                                <v-col
                                                                                    v-for="(value, attribute) in editedEntity.variants[item.index].category_attributes"
                                                                                    :key="`category-name-${attribute}`"
                                                                                    cols="12"
                                                                                >
                                                                                    <v-row align="center">
                                                                                        <v-col
                                                                                            cols="12"
                                                                                            class="position-relative pt-0 pb-4"
                                                                                        >
                                                                                            <ArbitraryInput
                                                                                                :value.sync="editedEntity.variants[item.index].category_attributes[attribute].value"
                                                                                                :type="editedEntity.variants[item.index].category_attributes[attribute].type"
                                                                                                :rules="formatRules(editedEntity.variants[item.index].category_attributes[attribute].type, editedEntity.variants[item.index].category_attributes[attribute].rules)"
                                                                                                :label="editedEntity.variants[item.index].category_attributes[attribute].name"
                                                                                                :icon="'input'"
                                                                                            />
                                                                                            <span
                                                                                                v-if="editedEntity.variants[item.index].category_attributes[attribute].rules && editedEntity.variants[item.index].category_attributes[attribute].rules.includes('required')"
                                                                                                class="required-fields"
                                                                                            >
                                                                                                *
                                                                                            </span>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </v-col>
                                                                            </template>
                                                                        </v-row>
                                                                    </td>
                                                                </template>
                                                                <template v-slot:footer.page-text="items">
                                                                    {{ items.pageStart }} - {{ items.pageStop }} {{ $t('components.pagination.of') }} {{ items.itemsLength }}
                                                                </template>
                                                            </v-data-table>
                                                        </template>
                                                        <v-row
                                                            justify="center"
                                                            align="center"
                                                        >
                                                            <v-col
                                                                sm="12"
                                                                class="text-center"
                                                            >
                                                                <v-row
                                                                    justify="center"
                                                                    align="center"
                                                                >
                                                                    <template v-if="editedEntity.variants.length">
                                                                        <v-col
                                                                            v-for="(value, key) in editedEntity.variants"
                                                                            :key="`variant-${key}`"
                                                                            cols="12"
                                                                            :class="{ 'mt-4 px-8' : !value.id, 'py-0' : value.id }"
                                                                        >
                                                                            <template v-if="!value.id">
                                                                                <v-row
                                                                                    justify="center"
                                                                                    align="center"
                                                                                >
                                                                                    <v-col
                                                                                        v-if="key === 0"
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                        class="text-left"
                                                                                    >
                                                                                        <h3 class="mb-0">{{ $t('entities.items.variants') }}</h3>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        v-else-if="editedIndex !== -1"
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                        class="text-left"
                                                                                    >
                                                                                        <h3 class="mb-0">{{ $t('entities.items.newVariants') }}</h3>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        :sm="key === 0  || editedIndex !== -1 ? '6' : '12'"
                                                                                        class="text-right"
                                                                                    >
                                                                                        <v-btn
                                                                                            @click="editedEntity.variants.splice(key, 1)"
                                                                                            color="error"
                                                                                            rounded
                                                                                        >
                                                                                            X
                                                                                        </v-btn>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="5"
                                                                                        class="position-relative py-0"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.variants[key].name"
                                                                                            @change="v => (editedEntity.variants[key].name = v)"
                                                                                            :rules="[required]"
                                                                                            :label="$t('generalAttributes.name')"
                                                                                            prepend-icon="mdi-format-title"
                                                                                        ></v-text-field>
                                                                                        <span class="required-fields">*</span>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="3"
                                                                                        class="position-relative py-0"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.variants[key].sku"
                                                                                            @change="v => (editedEntity.variants[key].sku = v)"
                                                                                            :rules="[required]"
                                                                                            :label="$t('entities.items.attributes.sku')"
                                                                                            prepend-icon="mdi-warehouse"
                                                                                        >
                                                                                            <TextFieldInfoTooltip
                                                                                                :text="$t('entities.items.skuInfo')"
                                                                                                slot="append"
                                                                                            />
                                                                                        </v-text-field>
                                                                                        <span class="required-fields">*</span>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="2"
                                                                                        class="py-0"
                                                                                    >
                                                                                        <v-switch
                                                                                            :input-value="editedEntity.variants[key].active"
                                                                                            @change="v => (editedEntity.variants[key].active = v)"
                                                                                            :label="$t('entities.items.attributes.active')"
                                                                                            prepend-icon="done"
                                                                                            inset
                                                                                        ></v-switch>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="2"
                                                                                        class="py-0"
                                                                                    >
                                                                                        <v-switch
                                                                                            :input-value="editedEntity.variants[key].promoted"
                                                                                            @change="v => (editedEntity.variants[key].promoted = v)"
                                                                                            :label="$t('entities.items.attributes.promoted')"
                                                                                            prepend-icon="done"
                                                                                            inset
                                                                                        ></v-switch>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="8"
                                                                                        class="py-0"
                                                                                    >
                                                                                        <v-textarea
                                                                                            v-model="editedEntity.variants[key].description"
                                                                                            name="content"
                                                                                            :label="$t('generalAttributes.description')"
                                                                                            prepend-icon="mdi-text"
                                                                                            rows="1"
                                                                                            auto-grow
                                                                                            persistent-hint
                                                                                        ></v-textarea>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="4"
                                                                                        class="position-relative py-0"
                                                                                    >
                                                                                        <v-flex>
                                                                                            <ImageInput
                                                                                                :file.sync="editedEntity.variants[key].file"
                                                                                                :alreadyHasImage="editedEntity.variants[key].image"
                                                                                            />
                                                                                        </v-flex>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        class="position-relative text-left"
                                                                                    >
                                                                                        <span class="font-weight-bold">{{ $t('entities.items.categoryAttributes') }}</span>
                                                                                    </v-col>
                                                                                    <template v-if="!editedEntity.category || !editedEntity.category.id">
                                                                                        <v-col
                                                                                            cols="12"
                                                                                            class="text-left pb-4"
                                                                                        >
                                                                                            <span>{{ $t('entities.items.chooseCategoryMessage') }}</span>
                                                                                        </v-col>
                                                                                    </template>
                                                                                    <template v-else-if="!editedEntity.category.attributes">
                                                                                        <v-col
                                                                                            cols="12"
                                                                                            class="text-left pb-4"
                                                                                        >
                                                                                            <span>{{ $t('entities.items.noCategoryAttributesRequired') }}</span>
                                                                                        </v-col>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <v-col
                                                                                            v-for="(value, attribute) in editedEntity.variants[key].category_attributes"
                                                                                            :key="`category-name-${attribute}`"
                                                                                            cols="12"
                                                                                            class="py-0"
                                                                                        >
                                                                                            <v-row align="center">
                                                                                                <v-col
                                                                                                    cols="12"
                                                                                                    class="position-relative"
                                                                                                >
                                                                                                    <ArbitraryInput
                                                                                                        :value.sync="editedEntity.variants[key].category_attributes[attribute].value"
                                                                                                        :type="editedEntity.variants[key].category_attributes[attribute].type"
                                                                                                        :rules="formatRules(editedEntity.variants[key].category_attributes[attribute].type, editedEntity.variants[key].category_attributes[attribute].rules)"
                                                                                                        :label="editedEntity.variants[key].category_attributes[attribute].name"
                                                                                                        :icon="'input'"
                                                                                                    />
                                                                                                    <span
                                                                                                        v-if="editedEntity.variants[key].category_attributes[attribute].rules.includes('required')"
                                                                                                        class="required-fields"
                                                                                                    >
                                                                                                        *
                                                                                                        </span>
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </v-col>
                                                                                    </template>
                                                                                </v-row>
                                                                            </template>
                                                                        </v-col>
                                                                    </template>
                                                                    <template v-else>
                                                                        <v-col
                                                                            sm="12"
                                                                            class="text-center mt-4 pb-0"
                                                                        >
                                                                            <p class="mb-0">
                                                                                {{ $t('entities.items.addVariantMessage') }}
                                                                            </p>
                                                                        </v-col>
                                                                    </template>
                                                                </v-row>
                                                            </v-col>
                                                            <v-col
                                                                sm="12"
                                                                class="text-center mb-4"
                                                            >
                                                                <v-btn
                                                                    @click="addVariant"
                                                                    color="primary"
                                                                >
                                                                    {{ $t('entities.items.addVariant') }}
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                        <ValidationErrors
                                                            v-if="validationErrors.length"
                                                            :errors="validationErrors"
                                                            class="mt-4"
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-container>
                                    </v-card-text>
                                    <v-divider/>
                                    <v-card-actions>
                                        <v-btn
                                            @click="closeEditDialog()"
                                            color="dark darken-1"
                                            text
                                        >
                                            {{ $t('miscellaneous.cancel') }}
                                        </v-btn>
                                        <v-spacer/>
                                        <v-btn
                                            @click="save"
                                            :disabled="(editedIndex === -1 && !editedEntity.variants.length) || saving"
                                            :loading="saving"
                                            color="primary"
                                            text
                                        >
                                            <span>{{ saving ? $t('miscellaneous.saving') : $t('miscellaneous.save') }}</span>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-form>
                        </v-dialog>
                        <v-dialog
                            v-model="deleteDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <DeleteToolbar
                                        :text="$t('entities.items.deleteEntity')"
                                        :closeDeleteDialog="closeDeleteDialog"
                                    />
                                    <v-container>
                                        <DialogImage
                                            :image="toDeleteEntity.image"
                                            :name="toDeleteEntity.name"
                                        />
                                        <v-card>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                    <tr>
                                                        <td>{{ $t('entities.categories.entity') }}</td>
                                                        <td class="font-weight-bold">
                                                            {{ toDeleteEntity.category ? toDeleteEntity.category.name : '/'}}
                                                        </td>
                                                        <td>{{ $t('generalAttributes.name') }}</td>
                                                        <td class="font-weight-bold">
                                                            {{ toDeleteEntity.name }}
                                                        </td>
                                                        <td>{{ $t('entities.items.attributes.active') }}</td>
                                                        <td class="font-weight-bold">
                                                            <Boolean :boolean="toDeleteEntity.active"/>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ $t('generalAttributes.description') }}</td>
                                                        <td
                                                            colspan="5"
                                                            class="font-weight-bold"
                                                        >
                                                            {{ toDeleteEntity.description || '/' }}
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                        <v-card class="mt-4">
                                            <v-row align="center">
                                                <v-col
                                                    cols="12"
                                                    class="py-0"
                                                >
                                                    <v-data-table
                                                        v-model="selectedDelete"
                                                        @click:row="clickDeleteRow"
                                                        @toggle-select-all="selectDeleteAllToggle"
                                                        :headers="variantHeaders"
                                                        :items="toDeleteEntity.variants"
                                                        :expanded.sync="selectedDelete"
                                                        :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                                                        :no-data-text="$t('components.noData.noEntriesFound')"
                                                        :footer-props="{ itemsPerPageText : $t('components.rowsPerPage.rows'), itemsPerPageAllText: $t('components.rowsPerPage.all') }"
                                                        class="do-not-apply-hover cursor-pointer"
                                                        show-select
                                                        dense
                                                    >
                                                        <template v-slot:top>
                                                            <v-toolbar flat>
                                                                <v-toolbar-title>
                                                                    <small>
                                                                        {{ $t('entities.items.variants') }}
                                                                    </small>
                                                                </v-toolbar-title>
                                                                <v-divider
                                                                    class="mx-4"
                                                                    vertical
                                                                    inset
                                                                ></v-divider>
                                                                <small class="mr-2">{{ toDeleteEntity.variants ? toDeleteEntity.variants.length : '' }}</small>
                                                            </v-toolbar>
                                                        </template>
                                                        <template v-slot:item.data-table-select="{ isSelected, select }">
                                                            <v-simple-checkbox
                                                                @input="select($event)"
                                                                :value="isSelected"
                                                            ></v-simple-checkbox>
                                                        </template>
                                                        <template v-slot:item.image="{ item }">
                                                            <template v-if="item.image">
                                                                <img
                                                                    :src="item.image"
                                                                    :alt="item.name"
                                                                    width="25px"
                                                                />
                                                            </template>
                                                            <template v-else>
                                                                /
                                                            </template>
                                                        </template>
                                                        <template v-slot:item.active="{ item }">
                                                            <Boolean :boolean="item.active"/>
                                                        </template>
                                                        <template v-slot:item.promoted="{ item }">
                                                            <Boolean :boolean="item.promoted"/>
                                                        </template>
                                                        <template v-slot:item.warehouses_count="{ item }">
                                                            <Boolean :boolean="item.warehouses_count"/>
                                                        </template>
                                                        <template v-slot:footer.page-text="items">
                                                            {{ items.pageStart }} - {{ items.pageStop }} {{ $t('components.pagination.of') }} {{ items.itemsLength }}
                                                        </template>
                                                    </v-data-table>
                                                    <ValidationErrors
                                                        v-if="validationErrors.length"
                                                        :errors="validationErrors"
                                                        class="mt-4"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <v-divider/>
                                <v-card-actions>
                                    <v-btn
                                        @click="closeDeleteDialog()"
                                        color="dark darken-3"
                                        text
                                    >
                                        {{ $t('miscellaneous.cancel') }}
                                    </v-btn>
                                    <v-spacer/>
                                    <v-btn
                                        @click="deleteEntity(true)"
                                        :disabled="deleting"
                                        :loading="deleting"
                                        color="error"
                                        text
                                    >
                                        <span>{{ deleting ? $t('miscellaneous.deleting') : $t('entities.items.deleteItemAndVariants') }}</span>
                                    </v-btn>
                                    <v-btn
                                        @click="deleteEntity(false)"
                                        :disabled="!selectedDelete.length || deleting"
                                        :loading="deleting"
                                        color="error"
                                        text
                                    >
                                        <span>{{ deleting ? $t('miscellaneous.deleting') : $t('entities.items.deleteVariants') }}</span>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.description="{ item }">
                    {{ item.description ? (item.description.length > 80 ? `${item.description.substring(0, 80)}...` : item.description) : '/' }}
                </template>
                <template v-slot:item.active="{ item }">
                    <Boolean :boolean="item.active"/>
                </template>
                <template v-slot:item.promoted="{ item }">
                    <Boolean :boolean="item.promoted"/>
                </template>
                <template v-slot:item.action="{ item }">
                    <ViewButton
                        :text="$t('entities.items.viewEntity')"
                        :item="item"
                        :openViewDialog="openViewDialog"
                    />
                    <EditButton
                        v-if="isAdmin"
                        :text="$t('entities.items.editEntity')"
                        :item="item"
                        :openEditDialog="openEditDialog"
                    />
                    <DeleteButton
                        v-if="isAdmin"
                        :text="$t('entities.items.deleteEntity')"
                        :item="item"
                        :openDeleteDialog="openDeleteDialog"
                    />
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import Boolean from '@/components/Boolean.vue';
import NoData from '@/components/crud/NoData.vue';
import GlobalButton from '@/components/GlobalButton.vue';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import ArbitraryInput from '@/components/ArbitraryInput.vue';
import TextFieldInfoTooltip from '@/components/TextFieldInfoTooltip';
import ImageInput from '@/components/ImageInput.vue';
import EditButton from '@/components/crud/edit/EditButton.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import DeleteButton from '@/components/crud/delete/DeleteButton.vue';
import DeleteToolbar from '@/components/crud/delete/DeleteToolbar.vue';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import ViewButton from '@/components/crud/view/ViewButton.vue';
import DialogImage from '@/components/DialogImage';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import Snackbar from '@/components/Snackbar.vue';
import Item from '@/models/shop/Item';
import Variant from '@/models/shop/Variant';
import Warehouse from '@/models/misc/Warehouse';
import Category from "@/models/shop/Category";
import FilesApi from "@/services/misc/FilesApi";

import currency from '@/mixins/currency';
import convertDate from '@/mixins/convert-date';
import thousandSeparator from '@/mixins/thousand-separator';
import isRole from '@/mixins/crud/computed/isRole';
import required from '@/mixins/rules/required';
import { VMoney } from 'v-money';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { showSnackbar } from "@/mixins/crud/methods/show-snackbar";
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import { deleteDialog } from '@/mixins/crud/watch/delete-dialog.js';

export default Vue.extend({
    components: {
        Boolean,
        NoData,
        GlobalButton,
        DialogImage,
        TextFieldInfoTooltip,
        ImageInput,
        EditButton,
        EditToolbar,
        DeleteButton,
        DeleteToolbar,
        RequiredFields,
        RefreshButton,
        ValidationErrors,
        ArbitraryInput,
        ViewButton,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
        Snackbar,
    },

    directives: { money : VMoney },

    mixins: [
        currency,
        convertDate,
        axiosCatch,
        showSnackbar,
        paginationWatch,
        deleteDialog,
        isRole,
        thousandSeparator,
        required,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        viewDialog: false,
        saving: false,
        deleting: false,
        typing: false,
        disabledCount: 0,
        apiURI: process.env.VUE_APP_SHOP_BASE_URI,
        route: 'items/global',
        entity: 'items',
        selected: [],
        selectedDelete: [],
        entities: [],
        warehouses: [],
        categories: [],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        selectedSearchAttributes: ['Name'],
        editDialog: false,
        deleteDialog: false,
        editedIndex: -1,
        dataTypes: [
            'string',
            'boolean',
            'integer',
            'numeric',
            'currency',
            'array',
            'date',
            'datetime',
            'time',
        ],
        file: null,
        image: null,
        editedEntity: {
            id: null,
            category: {
                id: null,
                name: '',
                attributes: {},
            },
            name: '',
            active: '',
            promoted: 0,
            description: '',
            sku: '',
            image: '',
            tags: [],
            reviews: [],
            shop_details: {
                title: '',
                subtitle: '',
                image: ''
            },
            variants: [],
        },
        defaultEntity: {
            id: null,
            category: {
                id: null,
                name: '',
                attributes: {},
            },
            name: '',
            active: '',
            promoted: 0,
            description: '',
            sku: '',
            image: '',
            tags: [],
            reviews: [],
            shop_details: {
                title: '',
                subtitle: '',
                image: ''
            },
            variants: [],
        },
        toDeleteEntity: {
            id: null,
            category: {
                id: null,
                name: '',
                attributes: {},
            },
            name: '',
            active: '',
            promoted: 0,
            description: '',
            sku: '',
            image: '',
            tags: [],
            reviews: [],
            shop_details: {
                title: '',
                subtitle: '',
                image: ''
            },
            variants: [],
        },
        defaultVariant: {
            id: null,
            category: {
                id: null,
                name: '',
                attributes: {},
            },
            name: '',
            active: 1,
            promoted: 0,
            description: '',
            sku: '',
            image: '',
            category_attributes: [],
            inventory_attributes: [],
            custom_attributes: [],
            warehouses: []
        },
    }),

    computed: {
        indexedItems() {
            if (this.editedEntity.variants) {
                return this.editedEntity.variants.map((items, index) => ({
                    ...items,
                    index: index
                }));
            }
            return [];

        },
        indexedVariants() {
            if (this.editedEntity.variants) {
                return this.editedEntity.variants
                    .filter(variant => variant.id)
                    .map((variants, index) => ({
                        ...variants,
                        index: index
                    }));
            }
            return [];
        },
        headers() {
            return [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('generalAttributes.description'), align: 'left', value: 'description' },
                { text: this.$t('entities.categories.entity'), align: 'left', value: 'category.name', sortable: false },
                { text: this.$t('entities.items.attributes.active'), align: 'left', value: 'active' },
                { text: this.$t('entities.items.attributes.promoted'), align: 'left', value: 'promoted' },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];
        },
        variantHeaders() {
            const headers = [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('miscellaneous.image'), align: 'left', value: 'image', sortable: false },
                { text: this.$t('entities.items.attributes.sku'), align: 'left', value: 'sku' },
                { text: this.$t('entities.items.attributes.active'), align: 'left', value: 'active' },
                { text: this.$t('entities.items.attributes.promoted'), align: 'left', value: 'promoted' },
            ];

            if (this.$store.getters.arena) {
                headers.splice(6, 0, { text: this.$t('entities.items.attributes.inArena', { arena: this.$store.getters.arena.name }), align: 'left', value: 'warehouses_count' })
            }

            return headers;
        },
        searchAttributes() {
            return [
                { label: this.$t('generalAttributes.name'), value: 'Name' },
                { label: this.$t('entities.categories.entity'), value: 'Category' }
            ]
        },
        formTitle() {
            return this.editDialog ? (this.$t(`entities.${this.entity}.${this.editedIndex === -1 ? 'new' : 'edit'}Entity`)) : '';
        },
        minPrice() {
            return v => (v !== `0,00${this.money.suffix}`) || this.$t('validation.minimumPrice');
        },
        integer() {
            return v => (!isNaN(v) && parseInt(Number(v)) == v && !isNaN(parseInt(v, 10))) || this.$t('validation.integer');
        },
        money() {
            return {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false,
                suffix: this.$store.getters.arena ? ` ${this.$store.getters.arena.currency}` : '',
            }
        },
    },

    watch: {
        viewDialog(val) {
            if (!val) {
                this.selected = [];
                this.disabledCount = 0;
                this.editedEntity = {...this.defaultEntity};
                this.editedIndex = -1;
                this.validationErrors = [];
            }
        },
        editDialog(val) {
            if (!val) {
                this.selected = [];
                this.defaultEntity.variants = [];
                this.editedEntity = {...this.defaultEntity};
                this.editedIndex = -1;
                this.validationErrors = [];
            }
        },
        deleteDialog(val) {
            if (!val) {
                this.selectedDelete = [];
                this.validationErrors = [];
            }
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchEntities() {
            try {
                const append = this.setParams();
                this.loading = true;
                let query = `?`;
                if (this.$store.getters.arena) {
                    query += `arena_id=${this.$store.getters.arena.id}`;
                }
                const response = await Item.api.get('/global', `${query}${append}`);
                this.entities = response.data.data.map((ItemDTO) => {
                    const item = new Item(ItemDTO);
                    item.variants = item.variants.map((VariantDTO) => {
                        // Displayed attributes are from the first warehouse
                        const variant = new Variant(VariantDTO);
                        // No converting of datetime
                        return variant;
                    })
                    return item;
                });
                response.data.meta.per_page = parseInt(response.data.meta.per_page);
                Object.assign(this.pagination, response.data.meta);
            } catch (e) {
                console.warn('Global Items API failed.');
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async fetchCategories() {
            try {
                this.loading = true;
                const response = await Category.api.get(1);
                this.categories = response.data.data.map(CategoryDTO => new Category(CategoryDTO));
            } catch (e) {
                console.warn('Categories API failed.');
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async fetchWarehouses() {
            try {
                this.loading = true;
                let query = ``;
                if (this.$store.getters.arena) {
                    query += `&arena_id=${this.$store.getters.arena.id}`;
                }
                const response = await Warehouse.api.get(0, `${query}`);
                this.warehouses = response.data.map(WarehouseDTO => new Warehouse(WarehouseDTO));
            } catch (e) {
                console.warn('Warehouse API failed.');
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            try {
                this.saving = true;

                if (!this.$refs.editForm.validate()) {
                    return;
                }

                if (this.file) {
                    await FilesApi.upload('shop', this.file)
                        .then((response) => {
                            this.file = null;
                            if (!response || response.status !== 200) {
                                this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                return;
                            }
                            this.image = response.data.link;
                        })
                        .catch(() => {
                            this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                        });
                }

                for (const i in this.editedEntity.variants) {
                    if (this.editedEntity.variants[i].file) {
                        await FilesApi.upload('shop', this.editedEntity.variants[i].file)
                            .then((response) => {
                                this.editedEntity.variants[i].file = null;
                                if (!response || response.status !== 200) {
                                    this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                }
                                this.editedEntity.variants[i].image = response.data.link;
                            })
                            .catch(() => {
                                this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                            });
                    }
                }

                const data = {
                    category_id: this.editedEntity.category.id,
                    name: this.editedEntity.name,
                    active: this.editedEntity.active ? 1 : 0,
                    promoted: this.editedEntity.promoted ? 1 : 0,
                    updating_item: this.editedIndex !== -1 ? 1 : 0,
                    image: this.image || this.editedEntity.image,
                };

                if (this.shopDetailsContainsSomething()) {
                    data.shop_details = this.editedEntity.shop_details;
                }

                if (this.editedIndex !== -1) {
                    if (this.editedEntity.reviews && this.editedEntity.reviews.length) {
                        data.reviews = this.editedEntity.reviews.map(function(review) {
                            return {
                                username: review.username,
                                location: review.location,
                                text: review.text,
                            }
                        });
                    }
                }

                if (this.editedEntity.description) {
                    data.description = this.editedEntity.description;
                }

                if (this.editedEntity.tags && this.editedEntity.tags.length) {
                    data.tags = this.editedEntity.tags;
                }

                data.variants = this.editedEntity.variants.map(variant => {
                    const formattedVariant = {};
                    if (variant.id) {
                        formattedVariant.id = variant.id;
                    }
                    formattedVariant.name = variant.name;
                    formattedVariant.sku = variant.sku;
                    formattedVariant.active = variant.active ? 1 : 0;
                    formattedVariant.promoted = variant.promoted ? 1 : 0;

                    if (variant.description) {
                        formattedVariant.description = variant.description;
                    }

                    if (variant.image) {
                        formattedVariant.image = variant.image;
                    }

                    if (variant.category_attributes && variant.category_attributes.length) {
                        formattedVariant.category_attributes = variant.category_attributes.map(ca => ({
                            name: ca.name,
                            value: this.getValue(ca)
                        }));
                    }

                    if (variant.inventory_attributes && variant.inventory_attributes.length) {
                        formattedVariant.inventory_attributes = variant.inventory_attributes.map(ia => ({
                            name: ia.name,
                            type: ia.type,
                            value: this.getValue(ia)
                        }));
                    }

                    if (variant.custom_attributes && variant.custom_attributes.length) {
                        formattedVariant.custom_attributes = variant.custom_attributes.map(ca => ({
                            name: ca.name,
                            type: ca.type,
                            value: this.getValue(ca)
                        }));
                    }

                    return formattedVariant;
                });

                if (this.editedIndex > -1) {
                    await Item.api.update(this.editedEntity.id, data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.items.notifications.update'));
                            this.closeEditDialog();
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                } else {
                    await Item.api.create(data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.items.notifications.creation'));
                            this.closeEditDialog();
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                }
            } catch (error) {
                console.warn(`Item Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        async addToArena() {
            try {
                this.saving = true;

                if (!this.$refs.viewForm.validate()) {
                    return;
                }

                const variants = this.selected.map(variant => ({
                    id: this.editedEntity.variants[variant.index].id,
                    warehouse_id: this.editedEntity.variants[variant.index].warehouse.id,
                    price: this.unFormat(this.editedEntity.variants[variant.index].price, 0),
                    cost: this.unFormat(this.editedEntity.variants[variant.index].cost, 0),
                    stock: this.editedEntity.variants[variant.index].stock,
                    taxes: this.editedEntity.variants[variant.index].taxes,
                }));

                await Warehouse.api.attachVariants({ arena_id: this.$store.getters.arena.id, variants: variants })
                    .then(() => {
                        this.showSnackbar('success', this.$t('entities.items.notifications.attachedVariants'));
                        this.viewDialog = false;
                        this.refresh();
                    })
                    .catch(error => {
                        this.axiosCatch(error);
                    });
            } catch (error) {
                console.warn(`Item Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        clickRow(item, row) {
            if (!item.warehouses_count && this.$store.getters.arena) {
                if (row.isExpanded) {
                    const index = this.selected.findIndex(i => i === item);
                    this.selected.splice(index, 1)
                } else {
                    this.selected.push(item);
                    if (this.warehouses.length) {
                        this.editedEntity.variants[item.index].warehouse = this.warehouses[0];
                    }
                    if (this.$store.getters.arena && this.$store.getters.arena.company.taxes && this.$store.getters.arena.company.taxes.length) {
                        this.$set(this.editedEntity.variants[item.index], 'taxes', [this.$store.getters.arena.company.taxes[0]]);
                    }
                }
            }

        },
        showForm(variants) {
            if (variants) {
                let count = 0;
                for (const variant of variants) {
                    if (variant.warehouses_count) {
                        count++;
                    }
                }
                return count !== variants.length;
            }
            return false;
        },
        selectAllToggle(props) {
            if (this.selected.length !== this.editedEntity.variants.length - this.disabledCount) {
                this.selected = [];
                this.disabledCount = 0;
                props.items.forEach(item => {
                    if (!item.warehouses_count) {
                        this.selected.push(item);
                    } else {
                        this.disabledCount++;
                    }
                });
            } else {
                this.disabledCount = 0;
                this.selected = [];
            }
        },
        rowClass(item) {
            if (item.warehouses_count < 1) {
                return 'cursor-pointer';
            }
        },
        addCustomAttributes(index) {
            if (!this.editedEntity.variants[index].custom_attributes) {
                this.$set(this.editedEntity.variants[index], 'custom_attributes', []);
            }
            // TODO => Value should be set depending on the type
            this.editedEntity.variants[index].custom_attributes.push({
                name: '',
                type: '',
                value: ''
            });
        },
        addInventoryAttributes(index) {
            if (!this.editedEntity.variants[index].inventory_attributes) {
                this.$set(this.editedEntity.variants[index], 'inventory_attributes', []);
            }
            // TODO => Value should be set depending on the type
            this.editedEntity.variants[index].inventory_attributes.push({
                name: '',
                type: '',
                value: ''
            });
        },
        addVariant() {
            this.editedEntity.variants.push(JSON.parse(JSON.stringify(this.defaultVariant)));
            this.updateCategory(this.editedEntity.category, this.editedEntity.variants.length - 1);
        },
        openViewDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            this.viewDialog = true;
        },
        openEditDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = JSON.parse(JSON.stringify(entity));

            if (this.$refs.editForm) {
                this.$refs.editForm.resetValidation();
            }

            // If updating the item and the item has category
            if (this.editedIndex !== -1 && this.editedEntity.category) {
                // Find item category
                const category = this.categories.find(category => category.id === this.editedEntity.category.id);
                // Set item category attributes
                this.editedEntity.category.attributes = category.attributes;
                // Loop through category attributes
                for (const j in category.attributes) {
                    // Loop through each item variant
                    for (const l in this.editedEntity.variants) {
                        // Loop through variant inventory attributes and format currency if exists
                        for (const k in this.editedEntity.variants[l].inventory_attributes) {
                            if (this.editedEntity.variants[l].inventory_attributes[k].type === 'currency') {
                                this.editedEntity.variants[l].inventory_attributes[k].value = this.format(
                                    this.editedEntity.variants[l].inventory_attributes[k].value,
                                    true,
                                    this.$store.getters.arena ? this.$store.getters.arena.currency : 'EUR'
                                );
                            }
                        }
                        // Loop through variant custom attributes and format currency if exists
                        for (const k in this.editedEntity.variants[l].custom_attributes) {
                            if (this.editedEntity.variants[l].custom_attributes[k].type === 'currency') {
                                this.editedEntity.variants[l].custom_attributes[k].value = this.format(
                                    this.editedEntity.variants[l].custom_attributes[k].value,
                                    true,
                                    this.$store.getters.arena ? this.$store.getters.arena.currency : 'EUR'
                                );
                            }
                        }
                        // Loop through variant category attributes and: set type, rules & format currency if exists. Value is unchanged.
                        for (const k in this.editedEntity.variants[l].category_attributes) {
                            if (this.editedEntity.variants[l].category_attributes[k].name === category.attributes[j].name) {
                                this.editedEntity.variants[l].category_attributes[k].type = category.attributes[j].type;
                                this.editedEntity.variants[l].category_attributes[k].rules = category.attributes[j].rules;
                                if (this.editedEntity.variants[l].category_attributes[k].type === 'currency') {
                                    this.editedEntity.variants[l].category_attributes[k].value = this.format(
                                        this.editedEntity.variants[l].category_attributes[k].value,
                                        true,
                                        this.$store.getters.arena ? this.$store.getters.arena.currency : 'EUR'
                                    );
                                }
                            }
                        }
                    }
                }
            }

            if (!this.editedEntity.reviews) {
                this.editedEntity.reviews = [];
            }

            if (!this.editedEntity.shop_details) {
                this.editedEntity.shop_details = {
                    title: '',
                    subtitle: '',
                    image: ''
                };
            }

            this.editDialog = true;
        },
        closeEditDialog() {
            this.editedEntity.variants = [];
            this.editedEntity = JSON.parse(JSON.stringify(this.defaultEntity));
            this.editedIndex = -1;
            this.image = null;
            this.editDialog = false;
        },
        openDeleteDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toDeleteEntity = {...entity};
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.toDeleteEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        updateCategory(category, variantIndex = -1) {
            this.editedEntity.category = category;

            // Assign category attributes to editing entity
            const attributes = [];
            for (const i in this.editedEntity.category.attributes) {
                attributes.push({
                    name: this.editedEntity.category.attributes[i].name,
                    rules: this.editedEntity.category.attributes[i].rules,
                    type: this.editedEntity.category.attributes[i].type,
                    value: this.getValue(this.editedEntity.category.attributes[i], true)
                });
            }

            if (variantIndex === -1) {
                for (const i in this.editedEntity.variants) {
                    this.editedEntity.variants[i].category_attributes = JSON.parse(JSON.stringify(attributes));
                }
            } else {
                this.editedEntity.variants[variantIndex].category_attributes = JSON.parse(JSON.stringify(attributes));
            }
        },
        updateAttributeValue(value, attribute) {
            if (value === 'currency') {
                attribute.value = this.format(0, true, this.$store.getters.arena.currency);
            } else if (value === 'string' || value === 'array') {
                attribute.value = '';
            } else if (value === 'boolean') {
                attribute.value = false;
            } else if (value === 'date' || value === 'datetime' || value === 'time') {
                attribute.value = null;
            } else if (value === 'integer' || value === 'numeric') {
                attribute.value = '';
            }
            attribute.type = value;
        },
        clickEditRow(item, row) {
            if (row.isExpanded) {
                const index = this.selected.findIndex(i => i === item);
                this.selected.splice(index, 1)
            } else {
                this.selected.push(item);
            }
        },
        selectEditAllToggle(props) {
            if (this.selected.length !== this.editedEntity.variants.length) {
                this.selected = [];
                props.items.forEach(item => {
                    this.selected.push(item);
                });
            } else {
                this.selected = [];
            }
        },
        clickDeleteRow(item, row) {
            if (row.isExpanded) {
                const index = this.selectedDelete.findIndex(i => i === item);
                this.selectedDelete.splice(index, 1)
            } else {
                this.selectedDelete.push(item);
            }
        },
        selectDeleteAllToggle(props) {
            if (this.selectedDelete.length !== this.toDeleteEntity.variants.length) {
                this.selectedDelete = [];
                props.items.forEach(item => {
                    this.selectedDelete.push(item);
                });
            } else {
                this.selectedDelete = [];
            }
        },
        async deleteEntity(deleteItem = false) {
            try {
                this.deleting = true;
                await Item.api.delete(this.entities[this.editedIndex].id, deleteItem ? null : { variants: this.selectedDelete.map(item => item.id) })
                    .then(() => {
                        if (this.pagination.search.length) {
                            this.pagination.search = '';
                        } else {
                            this.fetchEntities();
                        }
                        this.closeDeleteDialog();
                        this.selectedDelete = [];
                        this.showSnackbar(
                            'success',
                            deleteItem ? this.$t('entities.items.notifications.deletion') : this.$t('entities.items.notifications.variantsDeletion')
                        );
                    }).catch(error => {
                        this.axiosCatch(error);
                    });
            } catch (error) {
                console.warn(`Items Delete API failed.`);
                console.log(error);
            } finally {
                this.deleting = false;
            }
        },
        formatRules(type, rules) {
            // TODO => Format type rules for string, boolean, currency, array, date, datetime & all category possible rules.
            const rls = [];
            if (type === 'integer') {
                rls.push(v  => (!isNaN(v) && parseInt(Number(v)) == v && !isNaN(parseInt(v, 10))) || this.$t('validation.integer'));
            }

            if (type === 'numeric') {
                rls.push(v  => (!isNaN(v)) || this.$t('validation.integer'));
            }

            for (const i in rules) {
                if (rules[i] === 'required') {
                    rls.push(v => !!v || this.$t('validation.required'));
                } else if (rules[i] === 'date_format:H:i') {
                    rls.push(v => !!v || this.$t('entities.categories.validationAttributes.date_format:H:i'));
                } else if (rules[i] === 'between:1,100') {
                    rls.push(v => !!v || this.$t('entities.categories.validationAttributes.between:1,100'));
                }
            }

            return rls;
        },
        getValue(attribute, valueMissing = false) {
            if (attribute.type === 'boolean') {
                if (valueMissing) {
                    return 0;
                }
                return attribute.value ? 1 : 0;

            } else if (attribute.type === 'currency') {
                // Used for inserting data when updating category
                if (valueMissing) {
                    return this.format(0, 0);
                }
                // Used for sending data
                return this.unFormat(attribute.value, 0);
            } else if (attribute.type === 'datetime' && attribute.value) {
                attribute.value = this.fromCurrentDateTimeToUtc(attribute.value);
            }

            if (valueMissing) {
                return '';
            }

            return attribute.value;
        },
        shopDetailsContainsSomething() {
          return this.editedEntity.shop_details.title.length || this.editedEntity.shop_details.subtitle.length || this.editedEntity.shop_details.image.length;
        },
        refresh() {
            this.fetchEntities();
            this.fetchCategories();
            this.fetchWarehouses();
        }
    }
});

</script>

<style lang="scss">

.v-data-table tbody tr.v-data-table__expanded__content {
    box-shadow: inset 0 4px 0px -5px rgb(50 50 50 / 75%), inset 0 -4px 8px -5px rgb(50 50 50 / 75%) !important;
}

</style>
