<template>
    <div>
        <GlobalButton
            v-if="isAdmin || isManager"
            :route="'/global-games'"
            :text="$t('entities.games.globalGames')"
            icon="games"
        />
        <v-card class="px-6 pt-1">
            <v-row
                align="center"
                class="mt-4 pb-4"
            >
                <v-col
                    cols="auto"
                    class="pr-1"
                >
                    <p class="mt-3">
                        {{ $t('entities.games.title') }} |
                    </p>
                </v-col>
                <v-col
                    cols="auto"
                    class="px-0"
                >
                    <v-skeleton-loader
                        :loading="loading"
                        type="image"
                        width="15px"
                        height="15px"
                        class="d-inline-block"
                    >
                        <p class="d-inline-block mb-1">
                            {{ pagination.total }}
                        </p>
                    </v-skeleton-loader>
                </v-col>
                <v-col 
                    cols="auto"
                    class="pl-0 mb-3"
                >
                    <RefreshButton
                        :refresh="refresh"
                        :loading="loading || !$store.getters.arena"
                    />
                </v-col>
                <v-col
                    cols="auto"
                    class="grow"
                >
                    <DataTableHeader
                        :text="$t('components.rowsPerPage.entries')"
                        :pagination="pagination"
                        :searchAttributes="searchAttributes"
                        :selectedSearchAttributes.sync="selectedSearchAttributes"
                        :disabled="!$store.getters.arena || loading"
                        :rowPadding="'0'"
                    />
                </v-col>
                <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : 'auto'">
                    <v-btn
                        @click="saveOrder"
                        :disabled="orderChanged || saving"
                        :loading="saving"
                        :block="$vuetify.breakpoint.xsOnly"
                        color="primary"
                        class="mb-2"
                    >
                        {{ saving ? $t('miscellaneous.saving') : $t('entities.games.saveOrder') }}
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-dialog
                        v-model="editDialog"
                        max-width="1000px"
                    >
                        <v-card>
                            <v-card-text class="px-0 pb-0">
                                <EditToolbar
                                    :formTitle="formTitle"
                                    :closeEditDialog="closeEditDialog"
                                />
                                <v-container>
                                    <v-card class="px-4 pb-4">
                                        <v-form
                                            @submit.prevent="save"
                                            method="POST"
                                            ref="form"
                                        >
                                            <RequiredFields/>
                                            <DialogImage
                                                :image="editedEntity.image"
                                                :name="editedEntity.name"
                                            />
                                            <v-row align="center">
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="position-relative"
                                                >
                                                    <v-flex>
                                                        <ImageInput
                                                            :file.sync="imageFile"
                                                            :alreadyHasImage="editedEntity.image"
                                                        />
                                                    </v-flex>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="position-relative"
                                                >
                                                    <v-flex>
                                                        <ImageInput
                                                            :file.sync="iconFile"
                                                            :alreadyHasImage="editedEntity.icon"
                                                            :label="'miscellaneous.icon'"
                                                            :alreadyHasLabel="'miscellaneous.newIcon'"
                                                        />
                                                    </v-flex>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="position-relative"
                                                >
                                                    <v-flex>
                                                        <ImageInput
                                                            :file.sync="backgroundFile"
                                                            :alreadyHasImage="editedEntity.background"
                                                            :label="'entities.games.attributes.background'"
                                                            :alreadyHasLabel="'entities.games.newBackground'"
                                                        />
                                                    </v-flex>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="position-relative"
                                                >
                                                    <v-text-field
                                                        :value="editedEntity.video"
                                                        @change="v => (editedEntity.video = v)"
                                                        :label="$t('miscellaneous.video')"
                                                        prepend-icon="mdi-video"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="position-relative"
                                                >
                                                    <v-text-field
                                                        :value="editedEntity.name"
                                                        @change="v => (editedEntity.name = v)"
                                                        :rules="[required]"
                                                        :label="$t('generalAttributes.name')"
                                                        prepend-icon="mdi-format-title"
                                                        disabled
                                                    ></v-text-field>
                                                    <span class="required-fields">*</span>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="position-relative"
                                                >
                                                    <v-text-field
                                                        :value="editedEntity.description"
                                                        @change="v => (editedEntity.description = v)"
                                                        :label="$t('generalAttributes.description')"
                                                        prepend-icon="mdi-text"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="py-0"
                                                >
                                                    <v-combobox
                                                        :value="editedEntity.tags ? editedEntity.tags : []"
                                                        @change="v => (editedEntity.tags = v)"
                                                        :label="$t('entities.items.attributes.tags')"
                                                        deletable-chips
                                                        small-chips
                                                        clearable
                                                        multiple
                                                        prepend-icon="tag"
                                                    ></v-combobox>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                >
                                                  <v-combobox
                                                        :value="editedEntity.genres ? editedEntity.genres : []"
                                                        @change="v => (editedEntity.genres = v)"
                                                        :label="$t('entities.games.attributes.genres')"
                                                        deletable-chips
                                                        small-chips
                                                        clearable
                                                        multiple
                                                        prepend-icon="gamepad"
                                                    ></v-combobox>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-text-field
                                                        :value="editedEntity.display_order"
                                                        @change="v => (editedEntity.display_order = v)"
                                                        :label="$t('entities.games.attributes.displayOrder')"
                                                        prepend-icon="mdi-order-numeric-ascending"
                                                        type="number"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-switch
                                                        :input-value="editedEntity.active"
                                                        @change="v => (editedEntity.active = v)"
                                                        :label="$t('entities.games.attributes.active')"
                                                        prepend-icon="done"
                                                        inset
                                                    ></v-switch>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-text-field
                                                        :value="editedEntity.path"
                                                        @change="v => (editedEntity.path = v)"
                                                        :rules="[required]"
                                                        :label="$t('entities.games.attributes.path')"
                                                        prepend-icon="folder"
                                                    ></v-text-field>
                                                    <span class="required-fields">*</span>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="position-relative"
                                                >
                                                  <v-combobox
                                                        :value="editedEntity.procs ? editedEntity.procs : []"
                                                        @change="v => (editedEntity.procs = v)"
                                                        :label="$t('entities.games.attributes.processes')"
                                                        :rules="[v => v && !!v.length || $t('validation.required')]"
                                                        prepend-icon="memory"
                                                        deletable-chips
                                                        small-chips
                                                        clearable
                                                        multiple
                                                    ></v-combobox>
                                                    <span class="required-fields">*</span>
                                                </v-col>

                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-text-field
                                                        :value="editedEntity.args"
                                                        @change="v => (editedEntity.args = v)"
                                                        :label="$t('entities.games.attributes.args')"
                                                        prepend-icon="input"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    v-if="editDialog"
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-select
                                                        v-model="editedEntity.launchers"
                                                        :items="arenaApps"
                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                        :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                        item-text="name"
                                                        item-value="id"
                                                        prepend-icon="mdi-file-code"
                                                        deletable-chips
                                                        return-object
                                                        clearable
                                                        multiple
                                                        chips
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('entities.games.attributes.launchers') }}
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                            <ValidationErrors
                                                v-if="validationErrors.length"
                                                :errors="validationErrors"
                                                class="mt-6"
                                            />
                                        </v-form>
                                    </v-card>
                                </v-container>
                            </v-card-text>
                            <EditCardActions
                                :save="save"
                                :saving="saving"
                                :closeEditDialog="closeEditDialog"
                            />
                        </v-card>
                    </v-dialog>
                    <v-dialog
                        v-model="deleteDialog"
                        max-width="1000px"
                    >
                        <v-card>
                            <v-card-text class="px-0 pb-0">
                                <DeleteToolbar
                                    :text="$t('entities.games.deleteEntity')"
                                    :closeDeleteDialog="closeDeleteDialog"
                                />
                                <v-container>
                                    <DialogImage
                                        :image="toDeleteEntity.image"
                                        :name="toDeleteEntity.name"
                                    />
                                    <DialogImage
                                        :image="toDeleteEntity.background"
                                        :name="toDeleteEntity.name"
                                    />
                                    <v-card>
                                        <v-simple-table
                                            class="do-not-apply-hover"
                                            dense
                                        >
                                            <template v-slot:default>
                                                <tbody>
                                                    <tr>
                                                        <td>{{ $t('generalAttributes.name') }}</td>
                                                        <td class="font-weight-bold">
                                                            {{ toDeleteEntity.name }}
                                                        </td>
                                                        <td>{{ $t('miscellaneous.icon') }}</td>
                                                        <td class="font-weight-bold">
                                                            <template v-if="toDeleteEntity.icon">
                                                                <img
                                                                    :src="toDeleteEntity.icon"
                                                                    :alt="toDeleteEntity.name"
                                                                    width="20px"
                                                                />
                                                            </template>
                                                            <template v-else>
                                                                /
                                                            </template>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ $t('entities.games.attributes.args') }}</td>
                                                        <td class="font-weight-bold">
                                                            {{ toDeleteEntity.args || '/' }}
                                                        </td>
                                                        <td>{{ $t('entities.games.attributes.processes') }}</td>
                                                        <td class="font-weight-bold">
                                                            <v-chip
                                                                v-for="(process, key) in toDeleteEntity.procs"
                                                                :key="key"
                                                                color="primary"
                                                                class="mr-2"
                                                                small
                                                            >
                                                                {{ process }}
                                                            </v-chip>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ $t('generalAttributes.description') }}</td>
                                                        <td
                                                            colspan="3"
                                                            class="font-weight-bold"
                                                        >
                                                            {{ toDeleteEntity.description || '/' }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ $t('entities.games.attributes.genres') }}</td>
                                                        <td class="font-weight-bold">
                                                            {{ toDeleteEntity.genres || '/' }}
                                                        </td>
                                                        <td>{{ $t('miscellaneous.video') }}</td>
                                                        <td class="font-weight-bold">
                                                            {{ toDeleteEntity.video || '/' }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ $t('entities.games.attributes.tags') }}</td>
                                                        <td class="font-weight-bold">
                                                            {{ toDeleteEntity.tags || '/' }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>
                                </v-container>
                            </v-card-text>
                            <DeleteCardActions
                                :deleteEntity="deleteEntity"
                                :deleting="deleting"
                                :closeDeleteDialog="closeDeleteDialog"
                            />
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
            <v-divider/>
            <template v-if="!loading && entities.length">
                <draggable
                    :list="entities"
                    @change="updateListSortOrder($event)"
                    class="d-flex flex-wrap mt-4"
                    style="flex: 1 1 auto;"
                >
                    <v-col
                        v-for="(item, key) in entities"
                        :key="key"
                        cols="12"
                        sm="3"
                        class="d-flex flex-column"
                    >
                        <v-card
                            class="flex d-flex flex-column cursor-pointer"
                            :style="{ background : highlightGameWithUpdatedDisplayOrder(item) }"
                        >
                            <v-img
                                :src="item.image"
                                :alt="item.name"
                                height="200px"
                                class="text-left"
                            >
                                <v-row>
                                    <v-col>
                                        <v-chip
                                            v-if="item.active"
                                            class="font-weight-bold mt-4 ml-2"
                                            color="success"
                                            text-color="white"
                                        >
                                            <small>{{ $t('entities.games.attributes.active') }}</small>
                                        </v-chip>
                                        <v-chip
                                            v-else
                                            class="font-weight-bold mt-4 ml-2"
                                            color="error"
                                            text-color="white"
                                        >
                                            <small>{{ $t('entities.games.notActive') }}</small>
                                        </v-chip>
                                    </v-col>
                                    <v-col class="text-right">
                                        <v-chip
                                            class="font-weight-bold mt-4 mr-2"
                                            color="primary"
                                            text-color="white"
                                        >
                                            <small>#{{ item.display_order }}</small>
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </v-img>
                            <v-card-title class="justify-center">
                                {{ item.name }}
                            </v-card-title>
                            <v-divider/>
                            <v-card-actions class="px-4">
                                <template v-if="item.display_order !== item.original_display_order">
                                    <v-chip
                                        class="font-weight-bold"
                                        color="secondary"
                                        text-color="white"
                                    >
                                        <small>{{ $t('entities.games.displayOrderUpdated', { from: `#${item.original_display_order}`, to: `#${item.display_order}` }) }}</small>
                                    </v-chip>
                                </template>
                                <v-spacer/>
                                <EditButton
                                    :text="$t('entities.games.editEntity')"
                                    :item="item"
                                    :openEditDialog="openEditDialog"
                                />
                                <DeleteButton
                                    :text="$t('entities.games.deleteEntity')"
                                    :item="item"
                                    :openDeleteDialog="openDeleteDialog"
                                />
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </draggable>
            </template>
            <template v-else-if="!loading && !entities.length">
                <v-row class="mt-4">
                    <v-col class="text-center">
                        <template v-if="$store.getters.arena">
                            <p v-if="typing">
                                {{ $t('components.noData.fetchingEntries') }}
                            </p>
                            <p v-else-if="!typing && pagination.search.length">{{ $t('components.noData.filtered') }}: {{ pagination.search }}</p>
                            <p 
                                v-else
                                class="mb-0"
                            >
                                {{ $t('components.noData.noEntriesFound') }}
                            </p>
                            <v-btn
                                v-if="!typing && pagination.search.length"
                                @click="pagination.search = ''"
                                color="primary"
                                class="mb-3"
                                outlined
                            >
                                {{ $t('components.noData.clear') }}
                            </v-btn>
                            <v-btn
                                v-else-if="!typing && !pagination.search.length"
                                @click="fetchEntities()"
                                color="primary"
                                class="mt-3 mb-3"
                                outlined
                            >
                                {{ $t('components.noData.tryAgain') }}
                            </v-btn>
                        </template>
                        <template v-else>
                            <p>{{ $t('entities.games.chooseArena') }}</p>
                        </template>
                    </v-col>
                </v-row>
            </template>
            <template v-else>
                <v-row class="mt-4">
                    <CardSkeletonLoader :count="12"/>
                </v-row>
            </template>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import ImageInput from '@/components/ImageInput.vue';
import CardSkeletonLoader from '@/components/CardSkeletonLoader.vue';
import GlobalButton from '@/components/GlobalButton.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import EditButton from '@/components/crud/edit/EditButton.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import EditCardActions from '@/components/crud/edit/EditCardActions.vue';
import DeleteButton from '@/components/crud/delete/DeleteButton.vue';
import DeleteToolbar from '@/components/crud/delete/DeleteToolbar.vue';
import DeleteCardActions from '@/components/crud/delete/DeleteCardActions.vue';
import DialogImage from '@/components/DialogImage';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import Snackbar from '@/components/Snackbar.vue';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import ArenaGame from '@/models/configuration/ArenaGame';
import FilesApi from '@/services/misc/FilesApi';
import ArenaApp from '@/models/configuration/ArenaApp';

import isRole from '@/mixins/crud/computed/isRole';
import draggable from 'vuedraggable';
import required from '@/mixins/rules/required';
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import { editDialog } from '@/mixins/crud/watch/edit-dialog.js';
import { deleteDialog } from '@/mixins/crud/watch/delete-dialog.js';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { showSnackbar } from "@/mixins/crud/methods/show-snackbar";

export default Vue.extend({
    components: {
        ImageInput,
        CardSkeletonLoader,
        GlobalButton,
        RefreshButton,
        EditButton,
        EditToolbar,
        EditCardActions,
        DeleteButton,
        DeleteToolbar,
        DeleteCardActions,
        DialogImage,
        DataTableHeader,
        DataTableFooter,
        Snackbar,
        RequiredFields,
        ValidationErrors,
        draggable
    },

    mixins: [
        isRole,
        paginationWatch,
        editDialog,
        deleteDialog,
        axiosCatch,
        showSnackbar,
        required,
    ],

    data: () => ({
        loading: false,
        saving: false,
        deleting: false,
        typing: false,
        apiURI: process.env.VUE_APP_ARENA_HUB_BASE_URI,
        entity: 'games',
        route: 'games',
        entities: [],
        originalEntities: [],
        arenaApps: [],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 12,
            to: 0,
            total: 0
        },
        options: {
            sortBy: ['displayOrder'],
            sortDesc: []
        },
        selectedSearchAttributes: ['Name'],
        editDialog: false,
        deleteDialog: false,
        editedIndex: -1,
        imageFile: null,
        iconFile: null,
        backgroundFile: null,
        image: null,
        icon: null,
        background: null,
        editedEntity: {
            id: null,
            name: null,
            path: null,
            description: null,
            exec: null,
            display_order: null,
            original_display_order: null,
            procs: null,
            args: null,
            genres: null,
            image: null,
            icon: null,
            background: null,
            video: null,
            tags: null,
            launchers: null,
            active: null
        },
        defaultEntity: {
            id: null,
            name: null,
            path: null,
            description: null,
            exec: null,
            display_order: null,
            original_display_order: null,
            procs: null,
            args: null,
            genres: null,
            image: null,
            icon: null,
            background: null,
            video: null,
            tags: null,
            launchers: null,
            active: null
        },
        toDeleteEntity: {
            id: null,
            name: null,
            path: null,
            description: null,
            exec: null,
            display_order: null,
            original_display_order: null,
            procs: null,
            args: null,
            genres: null,
            image: null,
            icon: null,
            background: null,
            video: null,
            tags: null,
            launchers: null,
            active: null
        }
    }),

    computed: {
        searchAttributes() {
            return [
                { label: this.$t('generalAttributes.name'), value: 'Name' }
            ]
        },
        formTitle() {
            return this.editDialog ? (this.$t(`entities.${this.entity}.${this.editedIndex === -1 ? 'new' : 'edit'}Entity`)) : '';
        },
        orderChanged() {
            return !this.entities.some(arenaGame => arenaGame.display_order !== arenaGame.original_display_order);
        },
    },

    mounted() {
        if (this.$store.getters.arena) {
            this.refresh();
        }
    },

    methods: {
        async fetchEntities(append = `&size=${this.pagination.per_page}&sort=displayOrder,ASC`) {
            try {
                this.loading = true;
                if (this.$store.getters.arena) {
                    const response = await ArenaGame.api.get(this.$store.getters.arena.id, append);
                    const data = response.data;
                    this.entities = data.data.map((ArenaGameDTO) => {
                        const arenaGame = new ArenaGame(ArenaGameDTO);
                        arenaGame.original_display_order = ArenaGameDTO.display_order;
                        return arenaGame;
                    });
                    this.originalEntities = JSON.parse(JSON.stringify(this.entities));
                    this.pagination.current_page = data.metadata.page;
                    this.pagination.per_page = data.metadata.size;
                    this.pagination.total = data.metadata.count;
                    this.pagination.last_page = Math.ceil(data.metadata.count / data.metadata.size);
                    this.pagination.from = (data.metadata.page-1)*data.metadata.size + 1;
                    this.pagination.to = this.pagination.from + data.metadata.size - 1 > this.pagination.total ? this.pagination.total : this.pagination.from + data.metadata.size - 1;
                }
            } catch (e) {
                console.warn('ArenaGames API failed.');
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async fetchArenaApps() {
            try {
                const response = await ArenaApp.api.get(this.$store.getters.arena.id, 'size=100');
                const data = response.data;
                this.arenaApps = data.data
                    .filter(ArenaAppDTO => ArenaAppDTO.tags && ArenaAppDTO.tags.length)
                    .filter((ArenaAppDTO) => {
                        const arenaApp = new ArenaApp(ArenaAppDTO);
                        if (arenaApp.tags.some(tag => tag.toLowerCase().includes('launcher'))) {
                            return arenaApp;
                        }
                    });
            } catch (e) {
                console.warn('Arena Apps API failed.');
                console.log(e);
            }
        },
        async save() {
            try {
                this.saving = true;

                if (!this.$refs.form.validate()) {
                    return;
                }

                if (this.imageFile) {
                    await FilesApi.upload('arenas', this.imageFile)
                        .then((response) => {
                            this.imageFile = null;
                            if (!response || response.status !== 200) {
                                this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                return;
                            }
                            this.image = response.data.link;
                        })
                        .catch(() => {
                            this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                        });
                }

                if (this.iconFile) {
                    await FilesApi.upload('arenas', this.iconFile)
                        .then((response) => {
                            this.iconFile = null;
                            if (!response || response.status !== 200) {
                                this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                return;
                            }
                            this.icon = response.data.link;
                        })
                        .catch(() => {
                            this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                        });
                }

                if (this.backgroundFile) {
                    await FilesApi.upload('arenas', this.backgroundFile)
                        .then((response) => {
                            this.backgroundFile = null;
                            if (!response || response.status !== 200) {
                                this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                return;
                            }
                            this.background = response.data.link;
                        })
                        .catch(() => {
                            this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                        });
                }

                const data = {
                    active: this.editedEntity.active,
                    args: this.editedEntity.args || null,
                    background: this.background || this.editedEntity.background || null,
                    description: this.editedEntity.description || null,
                    display_order: this.editedEntity.display_order ? parseInt(this.editedEntity.display_order) : null,
                    genres: this.editedEntity.genres || null,
                    icon: this.icon || this.editedEntity.icon || null,
                    image: this.image || this.editedEntity.image || null,
                    app_ids: this.editedEntity.launchers ? this.editedEntity.launchers.map(launcher => launcher.id) : null,
                    name: this.editedEntity.name,
                    procs: this.editedEntity.procs || null,
                    path: this.editedEntity.path || null,
                    tags: this.editedEntity.tags || null,
                    video: this.editedEntity.video || null,
                }
                console.log(data.display_order)
                console.log(this.editedEntity.display_order)
                await ArenaGame.api.update(this.editedEntity.id, this.$store.getters.arena.id, data)
                    .then(() => {
                        if (this.pagination.search.length) {
                            this.pagination.search = '';
                        } else {
                            this.fetchEntities();
                        }
                        this.showSnackbar('success', this.$t('entities.games.notifications.update'));
                        this.closeEditDialog();
                    })
                    .catch(error => {
                        this.axiosCatch(error);
                    });
            } catch (error) {
                console.warn(`ArenaGame Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        async deleteEntity() {
            try {
                this.deleting = true;
                await ArenaGame.api.delete(this.$store.getters.arena.id, this.toDeleteEntity.id)
                    .then(() => {
                        this.closeDeleteDialog();
                        this.fetchEntities();
                        this.showSnackbar('success', this.$t(`entities.games.notifications.deletion`));
                    });
            } catch (error) {
                console.warn(`ArenaGame Delete API failed.`);
                console.log(error);
            } finally {
                this.deleting = false;
            }
        },
        async saveOrder() {
            try {
                this.saving = true;

                const data = this.entities.filter(arenaGame => arenaGame.display_order !== arenaGame.original_display_order)
                    .map(arenaGame => ({
                        id: arenaGame.id,
                        display_order: arenaGame.display_order
                    }));
                const payload = {
                    "data": data
                };
                await ArenaGame.api.updateDisplayOrder(this.$store.getters.arena.id, payload)
                    .then(() => {
                        if (this.pagination.search.length) {
                            this.pagination.search = '';
                        } else {
                            this.fetchEntities();
                        }
                        this.showSnackbar('success', this.$t('entities.games.notifications.updatedDisplayOrder'));
                    }).catch(error => {
                        this.axiosCatch(error);
                    });
            } catch (error) {
                console.warn(`ArenaGame Display Order Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        updateListSortOrder(event) {
            let beginning = event.moved.oldIndex;
            let end = event.moved.newIndex;

            if (beginning > end) {
                const helper = beginning;
                beginning = end;
                end = helper;
            }

            for (let iterator = beginning; iterator <= end; iterator++) {
                this.entities[iterator].display_order = this.originalEntities[iterator].display_order;
            }
        },
        openEditDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.editDialog = true;
        },
        closeEditDialog() {
            this.editDialog = false;
            this.editedEntity = {...this.defaultEntity};
            this.image = null;
            this.icon = null;
            this.background = null;
            this.editedIndex = -1;
        },
        openDeleteDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toDeleteEntity = {...entity};
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.toDeleteEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        highlightGameWithUpdatedDisplayOrder(item) {
            const c = this.$vuetify.theme.dark ? '58' : '240';
            return item.display_order !== item.original_display_order ? `rgb(${c}, ${c}, ${c})` : '';
        },
        refresh() {
            this.fetchEntities();
            this.fetchArenaApps();
        },
    }
});

</script>
