<template>
    <div>
        <template v-if="!type">
            {{ $t('entities.items.chooseTypeMessage') }}
        </template>
        <template v-else-if="type === 'boolean'">
            <v-switch
                :input-value="value"
                @change="$emit('update:value', $event)"
                :label="snakeToTitleCase(label)"
                :prepend-icon="icon"
                :disabled="disabled"
                inset
            ></v-switch>
        </template>
        <template v-else-if="type === 'string' || type === 'integer' || type === 'numeric'">
            <v-text-field
                :value="value"
                @change="$emit('update:value', $event)"
                :rules="rules"
                :label="snakeToTitleCase(label)"
                :prepend-icon="icon"
                :disabled="disabled"
                :type="type === 'string' ? 'text' : 'number'"
            ></v-text-field>
            <span class="required-fields">*</span>
        </template>
        <template v-else-if="type === 'currency'">
            <v-text-field
                :value="value"
                @change="$emit('update:value', $event)"
                :rules="rules"
                :label="snakeToTitleCase(label)"
                :disabled="disabled"
                v-money="money"
                prepend-icon="mdi-cash-multiple"
            ></v-text-field>
            <span class="required-fields">*</span>
        </template>
        <template v-else-if="type === 'array'">
            <template v-if="label === 'days'">
                <v-select
                    :value="value"
                    @change="$emit('update:value', $event)"
                    :items="days"
                    :label="snakeToTitleCase(label)"
                    :menu-props="{ bottom: true, offsetY: true }"
                    :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                    :rules="rules"
                    class="mt-4"
                    prepend-inner-icon="mdi-calendar"
                    item-text="name"
                    item-value="id"
                    deletable-chips
                    clearable
                    multiple
                    chips
                    solo
                ></v-select>
            </template>
            <template v-else-if="label === 'daily_reward'">
                <!-- TODO => Not updatable -->
                <v-textarea
                    :value="JSON.stringify(value, undefined, 4)"
                    :label="snakeToTitleCase(label)"
                    :rules="rules"
                    :prepend-icon="icon"
                    :disabled="disabled"
                    rows="1"
                    clear-icon="mdi-close-circle"
                    persistent-hint
                    clearable
                    auto-grow
                    outlined
                ></v-textarea>
                <span class="required-fields">*</span>
            </template>
            <template v-else-if="label === 'rewards'">
                <!-- TODO => Not updatable -->
                <v-textarea
                    :value="JSON.stringify(value, undefined, 4)"
                    :label="snakeToTitleCase(label)"
                    :rules="rules"
                    :prepend-icon="icon"
                    :disabled="disabled"
                    rows="1"
                    clear-icon="mdi-close-circle"
                    persistent-hint
                    clearable
                    auto-grow
                    outlined
                ></v-textarea>
                <span class="required-fields">*</span>
                <!-- TODO => Items as rewards -->
                <!--
                <v-row
                    v-for="row in value"
                    :key="`item-${row.item.name}`"
                >
                    <v-col
                        cols="12"
                        sm="6"
                    >
                        <v-select
                            :value="row.item"
                            @change="$emit('update:value', $event)"
                            :items="items"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                            :rules="rules"
                            :label="$t('entities.items.entity')"
                            prepend-inner-icon="mdi-shape-outline"
                            item-text="name"
                            item-value="id"
                            chips
                            solo
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                        <v-text-field
                            :value="row.probability"
                            @change="$emit('update:value', $event)"
                            :rules="rules"
                            :prepend-icon="icon"
                            :disabled="disabled"
                            label="Probability"
                        ></v-text-field>
                        <span class="required-fields">*</span>
                    </v-col>
                </v-row>
                -->
            </template>
            <template v-else>
                <v-text-field
                    :value="value"
                    @change="$emit('update:value', $event)"
                    :rules="rules"
                    :label="snakeToTitleCase(label)"
                    :prepend-icon="icon"
                    :disabled="disabled"
                    :hint="$t('entities.items.arrayTypeHint')"
                    persistent-hint
                ></v-text-field>
                <span class="required-fields">*</span>
            </template>
        </template>
        <template v-else-if="type === 'date'">
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="value"
                        :rules="rules"
                        :label="$t('miscellaneous.date')"
                        v-bind="attrs"
                        v-on="on"
                        prepend-icon="mdi-calendar"
                        readonly
                    ></v-text-field>
                </template>
                <v-date-picker
                    :value="value"
                    @input="menu = false"
                    @change="$emit('update:value', $event)"
                    :locale="$i18n.locale"
                    color="primary"
                ></v-date-picker>
            </v-menu>
        </template>
        <template v-else-if="type === 'datetime'">
            <v-menu
                v-model="dateTimeMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="value"
                        :rules="rules"
                        :label="$t('miscellaneous.dateAndTime')"
                        v-bind="attrs"
                        v-on="on"
                        prepend-icon="mdi-calendar-clock"
                        readonly
                    ></v-text-field>
                </template>
                <v-tabs
                    v-model="tab"
                    background-color="primary"
                    icons-and-text
                    centered
                    dark
                    grow
                >
                    <v-tabs-slider color="primary lighten-2"></v-tabs-slider>
                    <v-tab href="#date">
                        <v-icon>event</v-icon>
                    </v-tab>
                    <v-tab href="#time">
                        <v-icon>more_time</v-icon>
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item
                        value="date"
                        class="text-capitalize"
                    >
                        <v-date-picker
                            v-model="date"
                            :locale="$i18n.locale"
                            color="primary"
                        >
                        </v-date-picker>
                    </v-tab-item>
                    <v-tab-item value="time">
                        <v-time-picker
                            v-model="time"
                            format="24hr"
                            color="primary"
                        ></v-time-picker>
                    </v-tab-item>
                </v-tabs-items>
            </v-menu>
        </template>
        <template v-else-if="type === 'time'">
            <v-menu
                v-model="timeMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="value"
                        :rules="rules"
                        :label="label === 'from' || label === 'to' ? $t(`miscellaneous.${label}`) : $t('miscellaneous.time')"
                        v-bind="attrs"
                        v-on="on"
                        prepend-icon="mdi-clock"
                        readonly
                    ></v-text-field>
                </template>
                <v-time-picker
                    v-model="time"
                    format="24hr"
                    color="primary"
                ></v-time-picker>
            </v-menu>
        </template>
    </div>
</template>

<script>

import Vue from 'vue';

import { VMoney } from 'v-money';
import convertCase from '@/mixins/convert-case';

export default Vue.extend({

    directives: { money : VMoney },

    mixins: [
        convertCase,
    ],

    props: {
        type: {
            type: String
        },
        value: {
            type: null
        },
        rules: {
            type: Array
        },
        label: {
            type: String
        },
        icon: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        menu: false,
        dateTimeMenu: false,
        timeMenu: false,
        tab: null,
        date: null,
        time: null,
    }),

    computed: {
        money() {
            return {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false,
                suffix: ` ${this.$store.getters.arena.currency}`,
            }
        },
        days() {
            return [
                {
                    name: this.$t('miscellaneous.days.monday'),
                    id: 0,
                },
                {
                    name: this.$t('miscellaneous.days.tuesday'),
                    id: 1,
                },
                {
                    name: this.$t('miscellaneous.days.wednesday'),
                    id: 2,
                },
                {
                    name: this.$t('miscellaneous.days.thursday'),
                    id: 3,
                },
                {
                    name: this.$t('miscellaneous.days.friday'),
                    id: 4
                },
                {
                    name: this.$t('miscellaneous.days.saturday'),
                    id: 5,
                },
                {
                    name: this.$t('miscellaneous.days.sunday'),
                    id: 6,
                }
            ];
        }
    },

    watch: {
        timeMenu() {
            if (this.time) {
                this.$emit('update:value', this.time);
            }
        },
        dateTimeMenu(val) {
            this.tab = 'date';
            if (this.date && this.time) {
                const [year, month, day] = this.date.split('-');
                if (!val) {
                    this.$emit('update:value', `${year}-${month}-${day} ${this.time}`);
                }
            }
        },
        date() {
            this.tab = 'time';
        }
    },

})

</script>

<style>

.v-date-picker-header__value button {
    text-transform: capitalize !important;
}

</style>
