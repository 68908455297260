
<template>
    <div>
        <v-row v-if="!$store.getters.arena">
            <v-col
                cols="12"
                class="pt-0"
            >
                <v-alert type="info">
                    <span class="mb-0 text--white">{{ $t('adminDashboard.chooseArena') }}</span>
                </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="3">
                <Generated
            :date="dateString"
            :refresh="refresh"
            :loading="loadingShopStats || loadingArenaStats || loadingCoreStats"
        />
            </v-col>
            <v-col cols="12" sm="6"></v-col>
            <v-col cols="12" sm="3">
                <v-btn
                    class="global-button"
                    color="primary"
                    x-large
                    block
                    @click="openKPIsInNewTab"
                    style="text-transform: none;"
                >
                    <v-icon left>mdi-finance</v-icon>KPIs
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                sm="6"
                class="d-flex"
            >
                <v-card class="flex-grow-1 py-4 px-6">
                    <v-simple-table class="do-not-apply-hover">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="pl-0">
                                        <h2>{{ $t('entities.finance.todaysIncome') }}</h2>
                                    </th>
                                    <th class="text-right pr-0">
                                        <v-skeleton-loader
                                            :loading="loadingShopStats"
                                            class="d-inline-block"
                                            type="image"
                                            width="75px"
                                            height="25px"
                                        >
                                        <p class="title font-weight-bold mb-0">{{ format(todaysIncome, true, currency) }}</p>
                                    </v-skeleton-loader>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                    </v-simple-table>
                    <v-expansion-panels
                        :value="0"
                        :readonly="loadingShopStats"
                        flat
                    >
                        <v-expansion-panel>
                            <v-expansion-panel-content class="grey-border">
                                <v-simple-table class="do-not-apply-hover">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">{{ $t('miscellaneous.paymentMethod') }}</th>
                                                <th class="text-left">{{ $t('adminDashboard.amount') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-if="loadingShopStats">
                                                <tr>
                                                    <td
                                                        class="pl-3"
                                                        colspan="2"
                                                    >
                                                        {{ $t('adminDashboard.loadingIncomeByPaymentMethod') }}
                                                        <ProgressCircular 
                                                            color="primary"
                                                            class="mb-1"
                                                        />
                                                    </td>
                                                </tr>
                                            </template>
                                            <template v-else-if="Object.keys(payments).length">
                                                <tr
                                                    v-for="(item, key) in payments"
                                                    :key="key"
                                                >
                                                    <td class="pl-3">
                                                        {{ $t(`miscellaneous.paymentMethods.${titleToCamelCase(key)}`) }}
                                                    </td>
                                                    <td class="font-weight-bold">
                                                        {{ format(item[key], true, currency) }}
                                                    </td>
                                                </tr>
                                            </template>
                                            <template v-else>
                                                <tr>
                                                    <td
                                                        class="pl-3"
                                                        colspan="2"
                                                    >
                                                        {{ $t('adminDashboard.noIncome') }}
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
                <v-row>
                    <v-col 
                        cols="12"
                        sm="6"
                    >
                        <CardStat
                            :loading="loadingArenaStats"
                            :value="`${currentlyPlaying}`"
                            :text="$t('adminDashboard.currentlyPlaying')"
                            width="40px"
                        />
                    </v-col>
                    <v-col
                        cols="6"
                        sm="3"
                    >
                        <CardStat
                            :loading="loadingArenaStats"
                            :value="`${visitorsToday}`"
                            :text="$t('adminDashboard.visitorsToday')"
                            width="40px"
                        />
                    </v-col>
                    <v-col 
                        cols="6"
                        sm="3"
                    >
                        <CardStat
                            :loading="loadingArenaStats"
                            :value="`${firstVisits}`"
                            :text="$t('adminDashboard.firstVisits')"
                            width="40px"
                        />
                    </v-col>
                    <v-col cols="6">
                        <CardStat
                            :loading="loadingCoreStats"
                            :value="`${newUsers}`"
                            :text="$t('adminDashboard.newUsers')"
                            width="40px"
                        />
                    </v-col>
                    <v-col cols="6">
                        <CardStat
                            :loading="loadingShopStats"
                            :value="`${format(averageSpending, true, currency)}`"
                            :text="$t('adminDashboard.averageSpending')"
                            width="80px"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col 
                cols="6"
                sm="3"
            >
                <CardStat
                    :loading="loadingShopStats"
                    :value="`${addThousandSeparator(soldCoins)}`"
                    :text="$t('adminDashboard.soldCoins')"
                    :coins="true"
                />
            </v-col>
            <v-col 
                cols="6"
                sm="3"
            >
                <CardStat
                    :loading="loadingShopStats"
                    :value="`${addThousandSeparator(freeCoins)}`"
                    :text="$t('adminDashboard.freeCoins')"
                    :coins="true"
                />
            </v-col>
            <v-col 
                cols="6"
                sm="3"
            >
                <CardStat
                    :loading="loadingArenaStats"
                    :value="`${Math.round(averagePlaytime / 60)} min`"
                    :text="$t('adminDashboard.averagePlaytime')"
                />
            </v-col>
            <v-col 
                cols="6"
                sm="3"
            >
                <CardStat
                    :loading="loadingCoreStats"
                    :value="`${averageAge}`"
                    :text="$t('adminDashboard.averageAge')"
                    width="40px"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="elevation-2">
                    <DataTableSkeletonLoader :loading="initialLoad"/>
                    <!-- Mobile -->
                    <v-col
                        v-if="$store.getters.arena && $vuetify.breakpoint.xs"
                        cols="12"
                        class="text-center pb-1"
                    >
                        <RowsPerPage :pagination="pagination"/>
                    </v-col>
                    <v-data-table
                        v-show="!initialLoad"
                        :headers="dailyStatsHeaders"
                        :items="dailyStats"
                        :dense="$vuetify.breakpoint.xs && !loadingDailyStats"
                        :loading="loadingDailyStats"
                        :loading-text="$t('components.noData.fetchingEntries')"
                        :options.sync="options"
                        :server-items-length="pagination.total"
                        :items-per-page="pagination.per_page"
                        :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                        :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                        hide-default-footer
                    >
                        <template v-slot:top>
                            <v-toolbar
                                class="mb-10 pt-6"
                                flat
                            >
                                <v-toolbar-title>{{ $t('adminDashboard.dailyStats') }}</v-toolbar-title>
                                <v-divider
                                    class="mx-4"
                                    inset
                                    vertical
                                ></v-divider>
                                <small class="mr-2">{{ addThousandSeparator(pagination.total) }}</small>
                                <RefreshButton
                                    :refresh="fetchEntities"
                                    :loading="loadingDailyStats"
                                />
                                <v-spacer/>
                                <!-- Desktop -->
                                <v-col 
                                    v-if="$store.getters.arena && $vuetify.breakpoint.smAndUp"
                                    cols="6"
                                    sm="4"
                                    md="3"
                                    lg="2"
                                >
                                    <RowsPerPage :pagination="pagination"/>
                                </v-col>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.date="{ item }">
                            {{ formatDate(new Date(item.date), true, true, true) }} ({{ formatDay(item.date).substring(0, 3) }})
                        </template>
                        <template v-slot:item.income="{ item }">
                            {{ format(item.income, true, currency) }}
                        </template>
                        <template v-slot:item.average_spending="{ item }">
                            {{ format(item.average_spending, true, currency,) }}
                        </template>
                        <template v-slot:item.sold_coins="{ item }">
                            <span>{{ addThousandSeparator(item.sold_coins) }} <CoinImage/></span>
                        </template>
                        <template v-slot:item.free_coins="{ item }">
                            <span>{{ addThousandSeparator(item.free_coins) }} <CoinImage/></span>
                        </template>
                        <template v-slot:no-data>
                            <v-row>
                                <v-col class="my-4 text-center">
                                    <p class="mb-0">{{ $t('components.noData.noEntriesFound') }}</p>
                                    <v-btn
                                        v-if="$store.getters.arena && !pagination.search.length"
                                        @click="fetchEntities()"
                                        color="primary"
                                        class="mt-3"
                                        outlined
                                    >
                                        {{ $t('components.noData.tryAgain') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import Vue from 'vue';
import CardStat from '@/components/CardStat.vue';
import ProgressCircular from '@/components/ProgressCircular.vue';
import Generated from '@/components/Generated.vue';
import DashboardApi from '@/services/main/DashboardApi';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import RowsPerPage from '@/components/crud/RowsPerPage.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import CoinImage from '@/components/CoinImage.vue';

import currency from '@/mixins/currency';
import convertCase from '@/mixins/convert-case';
import thousandSeparator from '@/mixins/thousand-separator';
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import formatDate from '@/mixins/format-date';
import convertDate from '@/mixins/convert-date';
import formatDay from '@/mixins/format-day';

export default Vue.extend({
    components: {
        CardStat,
        ProgressCircular,
        Generated,
        DataTableSkeletonLoader,
        RowsPerPage,
        RefreshButton,
        CoinImage,
    },

    mixins: [
        currency,
        convertCase,
        thousandSeparator,
        paginationWatch,
        formatDate,
        convertDate,
        formatDay,
    ],

    data: () => ({
        dateString: '',
        initialLoad: true,
        loadingShopStats: false,
        loadingArenaStats: false,
        loadingCoreStats: false,
        loadingDailyStats: false,
        todaysIncome: 0,
        payments: [],
        currentlyPlaying: 0,
        visitorsToday: 0,
        firstVisits: 0,
        newUsers: 0,
        averageSpending: 0,
        soldCoins: 0,
        freeCoins: 0,
        averagePlaytime: 0,
        averageAge: 0,
        dailyStats: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
    }),

    computed: {
        dailyStatsHeaders() {
            return [
                { text: this.$t('miscellaneous.date'), align: 'left', value: 'date', sortable: false },
                { text: this.$t('miscellaneous.income'), align: 'left', value: 'income', sortable: false },
                { text: this.$t('adminDashboard.averageSpending'), align: 'left', value: 'average_spending', sortable: false },
                { text: this.$t('adminDashboard.soldCoins'), align: 'left', value: 'sold_coins', sortable: false },
                { text: this.$t('adminDashboard.freeCoins'), align: 'left', value: 'free_coins', sortable: false },
            ];
        },
        currency() {
            return this.$store.getters.arena ? this.$store.getters.arena.currency : '';
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        openKPIsInNewTab() {
            const url = 'https://kpi.friendlyfireesports.com';
            window.open(url, '_blank');
        },
        async fetchShopStats() {
            try {
                this.loadingShopStats = true;
                if (this.$store.getters.arena) {
                    await DashboardApi.shopStats(this.$store.getters.arena.id).then(resp => {
                        this.todaysIncome = resp.data.todays_income;
                        this.payments = resp.data.payment_methods;
                        this.averageSpending = resp.data.average_spending;
                        this.soldCoins = resp.data.sold_coins;
                        this.freeCoins = resp.data.free_coins;
                    })
                }
            } catch (error) {
                console.warn(`Shop API failed.`);
                console.log(error);
            } finally {
                this.loadingShopStats = false;
            }
        },
        async fetchArenaStats() {
            try {
                this.loadingArenaStats = true;
                if (this.$store.getters.arena) {
                    await DashboardApi.arenaStats(this.$store.getters.arena.id).then(resp => {
                        this.currentlyPlaying = resp.data.currently_playing;
                        this.visitorsToday = resp.data.visitors_today;
                        this.firstVisits = resp.data.first_visits;
                        this.averagePlaytime = resp.data.average_playtime_seconds;
                    })
                }
            } catch (error) {
                console.warn(`Arena API failed.`);
                console.log(error);
            } finally {
                this.loadingArenaStats = false;
            }
        },
        async fetchCoreStats() {
            try {
                this.loadingCoreStats = true;
                if (this.$store.getters.arena) {
                    await DashboardApi.coreStats(this.$store.getters.arena.id).then(resp => {
                        this.newUsers = resp.data.new_users;
                        this.averageAge = resp.data.average_age;
                    })
                }
            } catch (error) {
                console.warn(`Core API failed.`);
                console.log(error);
            } finally {
                this.loadingCoreStats = false;
            }
        },
        async fetchEntities(append = `&size=${this.pagination.per_page}&page=${this.pagination.current_page}`) {
            try {
                this.loadingDailyStats = true;
                if (this.$store.getters.arena) {
                    // TODO => Merge daily stats from core/arenas
                    await DashboardApi.dailyStats(this.$store.getters.arena.id, append).then(response => {
                        this.dailyStats = Object.entries(response.data.daily_stats.data).map(([key, value]) => {
                            return {
                                date: this.fromCurrentDateTimeToUtc(key),
                                income: value.income,
                                average_spending: value.average_spending,
                                sold_coins: value.sold_coins,
                                free_coins: value.free_coins,
                            };
                        });
                        response.data.daily_stats.per_page = parseInt(response.data.daily_stats.per_page);
                        this.pagination.current_page = response.data.daily_stats.current_page;
                        this.pagination.per_page = response.data.daily_stats.per_page;
                        this.pagination.total = response.data.daily_stats.total;
                        this.pagination.last_page = response.data.daily_stats.last_page;
                        this.pagination.from = response.data.daily_stats.from;
                        this.pagination.to = response.data.daily_stats.to;
                    })
                }
            } catch (error) {
                console.warn(`Daily Stats API failed.`);
                console.log(error);
            } finally {
                this.initialLoad = false;
                this.loadingDailyStats = false;
            }
        },
        refresh() {
            this.dateString = this.formatDate(new Date(), true, true);
            this.fetchShopStats();
            this.fetchArenaStats();
            this.fetchCoreStats();
            this.fetchEntities();
        },
    }
})
</script>

<style>

.v-expansion-panel-header, .v-expansion-panel-content__wrap {
    padding: 0 !important;
}

.grey-border {
    border: 1px solid #d8d8d8;
}

</style>
