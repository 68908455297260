<template>
    <router-view v-if="!loading"></router-view>
</template>

<script>

import Vue from 'vue';
import Arena from '@/models/setup/Arena';

import convertCase from '@/mixins/convert-case';
import isRole from '@/mixins/crud/computed/isRole';
import user from '@/mixins/crud/computed/user';

export default Vue.extend({

    mixins: [
        convertCase,
        isRole,
        user,
    ],

    data: () => ({
        loading: true,
    }),

    mounted() {
        if (this.$route.params.arena) {
            if (!this.$store.getters.arena && this.$store.getters.authenticated) {
                this.fetchArena(this.$route.params.arena);
            } else {
                this.loading = false;
            }
        } else {
            this.loading = false;
        }
    },

    methods: {
        async fetchArena(arena) {
            try {
                this.loading = true;
                const response = await Arena.api.findByName(this.snakeToTitleCase(arena.replaceAll('-', '_')), "include_fields=company&include_fields=payment_methods&include_fields=cash_registers&include_fields=invoice_settings&include_fields=invoice_settings&include_fields=address");

                if (!response.data) {
                    console.warn(`Arena not found.`);
                    this.resetStateAndRedirectToDashboard();
                    return;
                }

                if (!this.isAdmin) {
                    const employeeResponse = await Arena.api.getEmployees(response.data.id);
                    if (!Array.isArray(employeeResponse.data)) {
                        console.warn(`Expected an array of employees, but got:`, employeeResponse.data);
                        this.resetStateAndRedirectToDashboard();
                        return;
                    }
                    if (!employeeResponse.data.includes(this.user.id)) {
                        console.warn(`Insufficient permissions.`);
                        this.resetStateAndRedirectToDashboard();
                        return;
                    }
                }

                const data = response.data;
                const arenaData = {
                    id: data.id,
                    name: data.name,
                    timezone: data.timezone,
                    cashRegisters: data.cash_registers,
                    paymentMethods: data.payment_methods.map(function(item) {
                        return item.payment_method
                    }),
                    company: {
                        taxes: data.company.taxes,
                    },
                    currency: data.currency,
                    locale: data.locale,
                    coins_per_currency: data.coins_per_currency,
                    automaticallyOpenPrintUponInvoiceCreation: data.invoice_settings.automatically_open_print_upon_invoice_creation,
                };

                this.$store.commit('setArena', arenaData);
                this.$store.commit('setSelectedCashRegisterLabel', null);
                this.$store.commit('setCountry', data.address?.country_code.toLowerCase() | 'us');
                localStorage.setItem('preferred_arena', this.titleToKebabCase(arenaData.name));
                if (this.onlyArenaEntered()) {
                    this.pushToDashboard(this.$router.currentRoute.path);
                }
            } catch (e) {
                if (e.response?.status !== 404) {
                    console.warn(`Arena API failed.`);
                    console.log(e.response);
                }
                this.resetStateAndRedirectToDashboard();
            } finally {
                this.loading = false;
            }
        },
        pushToDashboard(arenaName) {
            this.$router.push(`${arenaName}/${(this.isAdmin || this.isManager) ? 'admin' : 'employee'}-dashboard`);
        },
        onlyArenaEntered() {
            return this.$router.currentRoute.name === 'entities.arenas.entity';
        },
        resetStateAndRedirectToDashboard() {
            this.$store.commit('setArena', null);
            this.$store.commit('setSelectedCashRegisterLabel', null);
            this.pushToDashboard('');
        }
    },
})

</script>
