import { AxiosResponse } from 'axios';
import axios from '@/services/axios';

export default abstract class ArenasApi {

    public static route = process.env.VUE_APP_ARENA_HUB_BASE_URI;
    public static entity = 'arenas';

    public static async get(params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}?${params}`);
    }

    public static async create(data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/${this.entity}`, data);
    }

    public static async read(id: bigint, params: string): Promise<AxiosResponse>{
        return await axios.get(`${this.route}/${this.entity}/${id}?${params}`);
    }

    public static async findByName(name: string, params: string): Promise<AxiosResponse>{
        return await axios.get(`${this.route}/${this.entity}/by-name/${name}?${params}`);
    }

    public static async update(id: bigint, data: object): Promise<AxiosResponse> {
        return await axios.patch(`${this.route}/${this.entity}/${id}`, data);
    }

    public static async delete(id: bigint): Promise<AxiosResponse> {
        return await axios.delete(`${this.route}/${this.entity}/${id}`);
    }

    public static async getWorkingHours(id: bigint): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}/${id}/working-hours`);
    }

     public static async updateWorkingHours(id: bigint, data: object): Promise<AxiosResponse> {
        return await axios.put(`${this.route}/${this.entity}/${id}/working-hours`, data);
    }

    public static async getBaseRate(id: bigint): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}/${id}/base-rate`);
    }

    public static async updateBaseRate(id: bigint, data: object): Promise<AxiosResponse> {
        return await axios.put(`${this.route}/${this.entity}/${id}/base-rate`, data);
    }

    public static async getTermsAndConditions(id: bigint): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}/${id}/terms-and-conditions`);
    }

    public static async getRateBonuses(id: bigint): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}/${id}/rate-bonus`);
    }

    public static async updateRateBonuses(id: bigint, data: object): Promise<AxiosResponse> {
        return await axios.put(`${this.route}/${this.entity}/${id}/rate-bonus`, data);
    }
    public static async getEmployees(id: bigint): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}/${id}/employees`);
    }

    public static async updateEmployees(id: bigint, data: object): Promise<AxiosResponse> {
        return await axios.put(`${this.route}/${this.entity}/${id}/employees`, data);
    }

    public static async createAddress(data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/addresses`, data);
    }

    public static async updateAddress(id: bigint, data: object): Promise<AxiosResponse> {
        return await axios.patch(`${this.route}/addresses/${id}`, data);
    }

    public static async createGeoMap(data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/geo-maps`, data);
    }

    public static async updateGeoMap(id: bigint, data: object): Promise<AxiosResponse> {
        return await axios.patch(`${this.route}/geo-maps/${id}`, data);
    }

    public static async createContact(data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/contacts`, data);
    }

    public static async updateContact(id: bigint, data: object): Promise<AxiosResponse> {
        return await axios.patch(`${this.route}/contacts/${id}`, data);
    }

    public static async createEquipment(data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/equipment`, data);
    }

    public static async updateEquipment(id: bigint, data: object): Promise<AxiosResponse> {
        return await axios.patch(`${this.route}/equipment/${id}`, data);
    }

    public static async deleteEquipment(id: bigint): Promise<AxiosResponse> {
        return await axios.delete(`${this.route}/equipment/${id}`);
    }
}

