import Login from '../views/Login.vue';
import Arena from '../views/Arena.vue';
import Profile from '../views/Profile.vue';
import EmployeeDashboard from '../views/main/dashboard/Employee.vue';
import AdminDashboard from '../views/main/dashboard/Admin.vue';
import Users from '../views/users/Users.vue';
import FaultyUsers from '../views/users/FaultyUsers.vue';
import PlayingNow from '../views/main/PlayingNow.vue';
import Purchase from '../views/shop/Purchase.vue';
import Items from '../views/shop/Items.vue';
import ArenaItems from '../views/shop/ArenaItems.vue';
import Categories from '../views/shop/Categories.vue';
import Invoices from '../views/shop/Invoices.vue';
import Transactions from '../views/shop/Transactions.vue';
import Articles from '../views/social/Articles.vue';
import Events from '../views/social/Events.vue';
import MobileNotifications from '../views/social/MobileNotifications.vue';
import Finance from '../views/data/Finance.vue';
import Analytics from '../views/data/Analytics.vue';
import Orders from '../views/shop/Orders.vue';
import Carts from '../views/shop/Carts.vue';
import Sessions from '../views/main/Sessions.vue';
import Games from '../views/configuration/Games.vue';
import ArenaGames from '../views/configuration/ArenaGames.vue';
import Apps from '../views/configuration/Apps.vue';
import ArenaApps from '../views/configuration/ArenaApps.vue';
import InventoryItemTypes from '../views/configuration/InventoryItemTypes.vue';
import MaxBonuses from '../views/configuration/MaxBonuses.vue';
import Ranks from '../views/users/Ranks.vue';
import Roles from '../views/users/Roles.vue';
import Settings from '../views/users/Settings.vue';
import Avatars from '../views/users/Avatars.vue';
import Subscriptions from '../views/users/Subscriptions.vue';
import Achievements from '../views/competitions/Achievements.vue';
import AchievementGroups from '../views/competitions/AchievementGroups.vue';
import Quests from '../views/competitions/Quests.vue';
import QuestTypes from '../views/competitions/QuestTypes.vue';
import QuestGroups from '../views/competitions/QuestGroups.vue';
import Skills from '../views/competitions/Skills.vue';
import Arenas from '../views/setup/Arenas.vue';
import Companies from '../views/setup/Companies.vue';
import NotFound from '../views/statuses/404.vue';
import Forbidden from '../views/statuses/403.vue';

import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '../i18n';

Vue.use(VueRouter);

import updateToken from '@/services/update-token';

const routes = [
    {
        path: '/',
        name: 'login.login',
        component: Login,
        meta: {
            title: 'FF | Login',
            auth: false
        }
    },
    {
        path: '/:arena?',
        name: 'entities.arenas.entity',
        component: Arena,
        meta: {
            title: 'FF | Arena',
            auth: true
        },
        children: [
            {
                path: 'profile',
                name: 'profile.title',
                component: Profile,
                meta: {
                    title: 'FF | Profile'
                }
            },
            {
                path: 'admin-dashboard',
                name: 'navigationDrawer.main.adminDashboard',
                component: AdminDashboard,
                meta: {
                    title: 'FF | Admin Dashboard',
                    roles: ['admin', 'manager']
                }
            },
            {
                path: 'employee-dashboard',
                name: 'navigationDrawer.main.employeeDashboard',
                component: EmployeeDashboard,
                meta: {
                    title: 'FF | Employee Dashboard',
                    roles: ['admin', 'employee', 'manager']
                }
            },
            {
                path: 'playing-now',
                name: 'navigationDrawer.main.playingNow',
                component: PlayingNow,
                meta: {
                    title: 'FF | Playing Now',
                    roles: ['admin', 'employee', 'manager']
                }
            },
            {
                path: 'sessions',
                name: 'navigationDrawer.main.sessions',
                component: Sessions,
                meta: {
                    title: 'FF | Sessions',
                    roles: ['admin', 'employee', 'manager']
                }
            },
            {
                path: 'users',
                name: 'navigationDrawer.users.users',
                component: Users,
                meta: {
                    title: 'FF | Users',
                    roles: ['admin', 'employee', 'manager']
                }
            },
            {
                path: 'faulty-users',
                name: 'entities.users.faultyUsers',
                component: FaultyUsers,
                meta: {
                    title: 'FF | Faulty Users',
                    roles: ['admin', 'employee', 'manager']
                }
            },
            {
                path: 'ranks',
                name: 'navigationDrawer.users.ranks',
                component: Ranks,
                meta: {
                    title: 'FF | Ranks',
                    roles: ['admin', 'manager']
                }
            },
            {
                path: 'roles',
                name: 'navigationDrawer.users.roles',
                component: Roles,
                meta: {
                    title: 'FF | Roles',
                    roles: ['admin', 'manager']
                }
            },
            {
                path: 'settings',
                name: 'navigationDrawer.users.settings',
                component: Settings,
                meta: {
                    title: 'FF | Settings',
                    roles: ['admin', 'manager']
                }
            },
            {
                path: 'avatars',
                name: 'navigationDrawer.users.avatars',
                component: Avatars,
                meta: {
                    title: 'FF | Avatars',
                    roles: ['admin', 'manager']
                }
            },
            {
                path: 'subscriptions',
                name: 'navigationDrawer.users.subscriptions',
                component: Subscriptions,
                meta: {
                    title: 'FF | Subscriptions',
                    roles: ['admin', 'manager']
                }
            },
            {
                path: 'purchase',
                name: 'navigationDrawer.shop.purchase',
                component: Purchase,
                meta: {
                    title: 'FF | Purchase',
                    roles: ['admin', 'employee', 'manager']
                }
            },
            {
                path: 'categories',
                name: 'navigationDrawer.shop.categories',
                component: Categories,
                meta: {
                    title: 'FF | Categories',
                    roles: ['admin', 'manager']
                }
            },
            {
                path: 'global-items',
                name: 'entities.items.globalItems',
                component: Items,
                meta: {
                    title: 'FF | Global Items',
                    roles: ['admin', 'manager']
                }
            },
            {
                path: 'items',
                name: 'navigationDrawer.shop.items',
                component: ArenaItems,
                meta: {
                    title: 'FF | Items',
                    roles: ['admin', 'employee', 'manager']
                }
            },
            {
                path: 'invoices',
                name: 'navigationDrawer.shop.invoices',
                component: Invoices,
                meta: {
                    title: 'FF | Invoices',
                    roles: ['admin', 'employee', 'manager']
                }
            },
            {
                path: 'transactions',
                name: 'entities.invoices.transactions',
                component: Transactions,
                meta: {
                    title: 'FF | Transactions',
                    roles: ['admin', 'employee', 'manager']
                }
            },
            {
                path: 'carts',
                name: 'navigationDrawer.shop.carts',
                component: Carts,
                meta: {
                    title: 'FF | Carts',
                    roles: ['admin', 'employee', 'manager']
                }
            },
            {
                path: 'orders',
                name: 'navigationDrawer.shop.orders',
                component: Orders,
                meta: {
                    title: 'FF | Orders',
                    roles: ['admin', 'employee', 'manager']
                }
            },
            {
                path: 'articles',
                name: 'navigationDrawer.newsManager.articles',
                component: Articles,
                meta: {
                    title: 'FF | Articles',
                    roles: ['admin', 'employee', 'manager']
                }
            },
            {
                path: 'events',
                name: 'navigationDrawer.newsManager.events',
                component: Events,
                meta: {
                    title: 'FF | Events',
                    roles: ['admin', 'employee', 'manager']
                }
            },
            {
                path: 'mobile-notifications',
                name: 'navigationDrawer.newsManager.mobileNotifications',
                component: MobileNotifications,
                meta: {
                    title: 'FF | Mobile Notifications',
                    roles: ['admin', 'manager']
                }
            },
            {
                path: 'finance',
                name: 'navigationDrawer.data.finance',
                component: Finance,
                meta: {
                    title: 'FF | Finance',
                    roles: ['admin', 'employee', 'manager']
                }
            },
            {
                path: 'analytics',
                name: 'navigationDrawer.data.analytics',
                component: Analytics,
                meta: {
                    title: 'FF | Analytics',
                    roles: ['admin', 'manager']
                }
            },
            {
                path: 'global-games',
                name: 'entities.games.globalGames',
                component: Games,
                meta: {
                    title: 'FF | Global Games',
                    roles: ['admin', 'manager']
                }
            },
            {
                path: 'games',
                name: 'navigationDrawer.configuration.games',
                component: ArenaGames,
                meta: {
                    title: 'FF | Games',
                    roles: ['admin', 'employee', 'manager']
                }
            },
            {
                path: 'global-apps',
                name: 'entities.apps.globalApps',
                component: Apps,
                meta: {
                    title: 'FF | Global Apps',
                    roles: ['admin', 'manager']
                }
            },
            {
                path: 'apps',
                name: 'navigationDrawer.configuration.apps',
                component: ArenaApps,
                meta: {
                    title: 'FF | Apps',
                    roles: ['admin', 'employee', 'manager']
                }
            },
            {
                path: 'inventory-item-types',
                name: 'entities.inventoryItemTypes.title',
                component: InventoryItemTypes,
                meta: {
                    title: 'FF | Inventory Item Types',
                    roles: ['admin', 'manager']
                }
            },
            {
                path: 'max-bonuses',
                name: 'entities.maxBonuses.title',
                component: MaxBonuses,
                meta: {
                    title: 'FF | Max Bonuses',
                    roles: ['admin', 'manager']
                }
            },
            {
                path: 'achievements',
                name: 'navigationDrawer.competitions.achievements',
                component: Achievements,
                meta: {
                    title: 'FF | Achievements',
                    roles: ['admin']
                }
            },
            {
                path: 'achievement-groups',
                name: 'entities.achievementGroups.title',
                component: AchievementGroups,
                meta: {
                    title: 'FF | Achievement Groups',
                    roles: ['admin']
                }
            },
            {
                path: 'quests',
                name: 'navigationDrawer.competitions.quests',
                component: Quests,
                meta: {
                    title: 'FF | Quests',
                    roles: ['admin']
                }
            },
            {
                path: 'quest-types',
                name: 'entities.questTypes.title',
                component: QuestTypes,
                meta: {
                    title: 'FF | Quest Types',
                    roles: ['admin']
                }
            },
            {
                path: 'quest-groups',
                name: 'entities.questGroups.title',
                component: QuestGroups,
                meta: {
                    title: 'FF | Quest Groups',
                    roles: ['admin']
                }
            },
            {
                path: 'skills',
                name: 'navigationDrawer.competitions.skills',
                component: Skills,
                meta: {
                    title: 'FF | Skills',
                    roles: ['admin']
                }
            },
            {
                path: 'arenas',
                name: 'navigationDrawer.setup.arenas',
                component: Arenas,
                meta: {
                    title: 'FF | Arenas',
                    roles: ['admin']
                }
            },
            {
                path: 'companies',
                name: 'navigationDrawer.setup.companies',
                component: Companies,
                meta: {
                    title: 'FF | Companies',
                    roles: ['admin']
                }
            }
        ]
    },
    {
        path: '/statuses/403',
        name: '403',
        component: Forbidden,
        meta: {
            title: 'FF | Forbidden',
            auth: false
        }
    },
    {
        path: '/statuses/404',
        name: '404',
        component: NotFound,
        meta: {
            title: 'FF | Not Found',
            auth: false
        }
    },
    {
        path: '*',
        redirect: '/statuses/404'
    }
];

function visiting(route: string, location: string) {
    return route === location;
}

function tokenSet(token: string) {
    return localStorage.getItem(token) !== null;
}

function preferredArena() {
    return localStorage.getItem('preferred_arena');
}

function getUser() {
    const user = localStorage.getItem('user');
    if (!user) {
        return user;
    } else {
        return decodeURIComponent(escape(window.atob(user)));
    }
}

function isRole(roles: Array<string>) {
    const user = getUser();
    if (!user) {
        return false;
    }
    const userRoles = JSON.parse(user).roles;
    return userRoles.some((r: string) => roles.includes(r))
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});

router.beforeEach(async (to, from, next) => {
    if (tokenSet('access_token')) {
        await updateToken();
    }

    document.title = 'FF | ' + i18n.t(`${to.name}`).toString();

    // Check if user has role for requested page
    if (to.meta && to.meta.roles) {
        const user = getUser();
        if (!user) {
            return next('/');
        } else {
            if (!to.meta.roles.some((r: string) => JSON.parse(user).roles.indexOf(r) >= 0)) {
                return next(`/statuses/403`)
            }
        }
    }

    if (!tokenSet('access_token') && to.matched.some(m => m.meta.auth)) {
        // If no token but route requires auth redirect to login
        next('/');
    } else if (tokenSet('access_token') && visiting(to.path, '/')) {
        // If token set but visiting login page redirect to dashboard with preferred arena
        next(`/${preferredArena() ? preferredArena() + '/' : ''}${isRole(['admin', 'manager']) ? 'admin' : 'employee'}-dashboard`)
    } else {
        next();
    }
});

export default router;
