<template>
    <div>
        <GlobalButton
            :route="'/apps'"
            :text="$t('entities.apps.title')"
            icon="apps"
        />
        <v-card class="px-6 pt-1">
            <v-row
                align="center"
                class="mt-4 pb-4"
            >
                <v-col
                    cols="auto"
                    class="pr-1"
                >
                    <p class="mt-3">
                        {{ $t('entities.apps.globalApps') }} |
                    </p>
                </v-col>
                <v-col
                    cols="auto"
                    class="px-0"
                >
                    <v-skeleton-loader
                        :loading="loading"
                        type="image"
                        width="15px"
                        height="15px"
                        class="d-inline-block"
                    >
                        <p class="d-inline-block mb-1">
                            {{ pagination.total }}
                        </p>
                    </v-skeleton-loader>
                </v-col>
                <v-col 
                    cols="auto"
                    class="pl-0 mb-3"
                >
                    <RefreshButton
                        :refresh="refresh"
                        :loading="loading"
                    />
                </v-col>
                <v-col
                    cols="auto"
                    class="grow"
                >
                    <DataTableHeader
                        :text="$t('components.rowsPerPage.entries')"
                        :pagination="pagination"
                        :searchAttributes="searchAttributes"
                        :selectedSearchAttributes.sync="selectedSearchAttributes"
                        :disabled="loading"
                        :rowPadding="'0'"
                    />
                </v-col>
                <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : 'auto'">
                    <v-dialog
                        v-model="editDialog"
                        max-width="1000px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-if="isAdmin"
                                v-on="on"
                                :block="$vuetify.breakpoint.xsOnly"
                                color="primary"
                                class="mb-2"
                                dark
                            >
                                {{ $t('miscellaneous.add') }}
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-text class="px-0 pb-0">
                                <v-toolbar
                                    :color="addingToArena ? 'green darken-3' : 'primary'"
                                    class="elevation-2"
                                >
                                    <v-toolbar-title class="ml-0">
                                        <span class="font-weight-bold white--text">
                                            {{ formTitle }}
                                        </span>
                                    </v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="closeEditDialog"
                                        icon
                                        dark
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-container>
                                    <v-card class="px-4 pb-4">
                                        <v-form
                                            @submit.prevent="save"
                                            method="POST"
                                            ref="form"
                                        >
                                            <RequiredFields/>
                                            <DialogImage
                                                :image="editedEntity.image"
                                                :name="editedEntity.name"
                                            />
                                            <v-row align="center">
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="position-relative"
                                                >
                                                    <v-flex>
                                                        <ImageInput
                                                            :file.sync="imageFile"
                                                            :alreadyHasImage="editedEntity.image"
                                                        />
                                                    </v-flex>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="position-relative"
                                                >
                                                    <v-flex>
                                                        <ImageInput
                                                            :file.sync="iconFile"
                                                            :alreadyHasImage="editedEntity.icon"
                                                            :label="'miscellaneous.icon'"
                                                            :alreadyHasLabel="'miscellaneous.newIcon'"
                                                        />
                                                    </v-flex>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="position-relative"
                                                >
                                                    <v-text-field
                                                        :value="editedEntity.name"
                                                        @change="v => (editedEntity.name = v)"
                                                        :rules="[required]"
                                                        :label="$t('generalAttributes.name')"
                                                        :disabled="addingToArena"
                                                        prepend-icon="mdi-format-title"
                                                    ></v-text-field>
                                                    <span class="required-fields">*</span>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="position-relative"
                                                >
                                                    <v-text-field
                                                        :value="editedEntity.description"
                                                        @change="v => (editedEntity.description = v)"
                                                        :label="$t('generalAttributes.description')"
                                                        prepend-icon="mdi-text"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="py-0"
                                                >
                                                    <v-combobox
                                                        :value="editedEntity.tags"
                                                        @change="v => (editedEntity.tags = v)"
                                                        :label="$t('entities.items.attributes.tags')"
                                                        prepend-icon="tag"
                                                        deletable-chips
                                                        small-chips
                                                        clearable
                                                        multiple
                                                    ></v-combobox>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-switch
                                                        :input-value="editedEntity.active"
                                                        @change="v => (editedEntity.active = v)"
                                                        :label="$t('entities.games.attributes.active')"
                                                        prepend-icon="done"
                                                        inset
                                                    ></v-switch>
                                                </v-col>
                                                <template v-if="addingToArena">
                                                    <v-col
                                                        cols="12"
                                                        class="py-0"
                                                    >
                                                        <h3 class="mt-4 grey--text text--darken-1">
                                                            {{ $t('entities.games.arenaAttributes') }}
                                                        </h3>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.path"
                                                            @change="v => (editedEntity.path = v)"
                                                            :rules="[required]"
                                                            :label="$t('entities.games.attributes.path')"
                                                            prepend-icon="folder"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.args"
                                                            @change="v => (editedEntity.args = v)"
                                                            :label="$t('entities.games.attributes.args')"
                                                            prepend-icon="input"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="position-relative"
                                                    >
                                                        <v-combobox
                                                            :value="editedEntity.procs"
                                                            @change="v => (editedEntity.procs = v)"
                                                            :label="$t('entities.games.attributes.processes')"
                                                            :rules="[v => v && !!v.length || $t('validation.required')]"
                                                            prepend-icon="memory"
                                                            deletable-chips
                                                            small-chips
                                                            clearable
                                                            multiple
                                                        ></v-combobox>
                                                        <span class="required-fields pr-2">*</span>
                                                    </v-col>
                                                </template>
                                            </v-row>
                                            <ValidationErrors
                                                v-if="validationErrors.length"
                                                :errors="validationErrors"
                                                class="mt-6"
                                            />
                                        </v-form>
                                    </v-card>
                                </v-container>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                                <v-btn
                                    @click="closeEditDialog()"
                                    color="dark darken-1"
                                    text
                                >
                                    {{ $t('miscellaneous.cancel') }}
                                </v-btn>
                                <v-spacer/>
                                <v-btn
                                    @click="save"
                                    :disabled="saving"
                                    :loading="saving"
                                    :color="addingToArena ? 'green darken-3' : 'primary'"
                                    text
                                >
                                    <span>{{ saving ? $t('miscellaneous.saving') : (addingToArena ? $t('miscellaneous.addToArena', { arena: $store.getters.arena.name }) : $t('miscellaneous.save')) }}</span>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
            <v-divider/>
            <v-row
                v-if="!loading && entities.length"
                class="mt-4"
            >
                <v-col
                    v-for="(item, key) in entities"
                    :key="key"
                    cols="12"
                    sm="3"
                    class="d-flex flex-column"
                >
                    <v-card class="flex d-flex flex-column">
                        <v-img
                            :src="item.image"
                            :alt="item.name"
                            height="200px"
                            class="text-left"
                        >
                            <template v-if="item.active">
                                <v-chip
                                    class="font-weight-bold mt-4 ml-2"
                                    color="success"
                                    text-color="white"
                                >
                                    <small>{{ $t('entities.apps.attributes.active') }}</small>
                                </v-chip>
                            </template>
                            <template v-else>
                                <v-chip
                                    class="font-weight-bold mt-4 ml-2"
                                    color="error"
                                    text-color="white"
                                >
                                    <small>{{ $t('entities.apps.notActive') }}</small>
                                </v-chip>
                            </template>
                        </v-img>
                        <v-card-title class="justify-center">
                            {{ item.name }}
                        </v-card-title>
                        <v-divider/>
                        <v-card-actions class="px-4">
                            <v-spacer/>
                            <v-spacer/>
                            <v-tooltip
                                v-if="$store.getters.arena && !alreadyInArena(item)"
                                color="transparent"
                                top
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        @click="openEditDialog(item, true)"
                                        v-on="on"
                                        color="green darken-3"
                                        class="glow ml-2"
                                        depressed
                                        x-small
                                        dark
                                        fab
                                    >
                                        <v-icon
                                            small
                                            dark
                                        >
                                            add_to_queue
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-card class="elevation-2 px-4 py-2">
                                    <span>{{ $t('miscellaneous.addToArena', { arena: $store.getters.arena.name }) }}</span>
                                </v-card>
                            </v-tooltip>
                            <v-tooltip
                                color="transparent"
                                top
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="isAdmin"
                                        @click="openEditDialog(item, false)"
                                        v-on="on"
                                        color="primary"
                                        class="glow ml-2"
                                        depressed
                                        x-small
                                        dark
                                        fab
                                    >
                                        <v-icon
                                            small
                                            dark
                                        >
                                            edit
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-card class="elevation-2 px-4 py-2">
                                    <span>{{ $t('entities.apps.editEntity') }}</span>
                                </v-card>
                            </v-tooltip>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row
                v-else-if="!loading && !entities.length"
                class="mt-4"
            >
                <v-col class="text-center">
                    <p v-if="typing">
                        {{ $t('components.noData.fetchingEntries') }}
                    </p>
                    <p v-else-if="!typing && pagination.search.length">{{ $t('components.noData.filtered') }}: {{ pagination.search }}</p>
                    <p 
                        v-else
                        class="mb-0"
                    >
                        {{ $t('components.noData.noEntriesFound') }}
                    </p>
                    <v-btn
                        v-if="!typing && pagination.search.length"
                        @click="pagination.search = ''"
                        color="primary"
                        class="mb-3"
                        outlined
                    >
                        {{ $t('components.noData.clear') }}
                    </v-btn>
                    <v-btn
                        v-else-if="!typing && !pagination.search.length"
                        @click="fetchEntities()"
                        color="primary"
                        class="mt-3 mb-3"
                        outlined
                    >
                        {{ $t('components.noData.tryAgain') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-row
                v-else
                class="mt-4"
            >
                <CardSkeletonLoader :count="12"/>
            </v-row>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import ImageInput from '@/components/ImageInput.vue';
import CardSkeletonLoader from '@/components/CardSkeletonLoader.vue';
import GlobalButton from '@/components/GlobalButton.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import DialogImage from '@/components/DialogImage';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import Snackbar from '@/components/Snackbar.vue';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import App from '@/models/configuration/App';
import FilesApi from '@/services/misc/FilesApi';
import ArenaApp from '@/models/configuration/ArenaApp';

import isRole from '@/mixins/crud/computed/isRole';
import required from '@/mixins/rules/required';
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import { editDialog } from '@/mixins/crud/watch/edit-dialog.js';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { showSnackbar } from "@/mixins/crud/methods/show-snackbar";

export default Vue.extend({
    components: {
        ImageInput,
        CardSkeletonLoader,
        GlobalButton,
        RefreshButton,
        DialogImage,
        DataTableHeader,
        DataTableFooter,
        Snackbar,
        RequiredFields,
        ValidationErrors,
    },

    mixins: [
        paginationWatch,
        editDialog,
        axiosCatch,
        isRole,
        showSnackbar,
        required,
    ],

    data: () => ({
        loading: false,
        saving: false,
        addingToArena: false,
        typing: false,
        apiURI: process.env.VUE_APP_CORE_BASE_URI,
        route: 'apps',
        entity: 'apps',
        entities: [],
        arenaApps: [],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 12,
            to: 0,
            total: 0
        },
        options: {},
        selectedSearchAttributes: ['Name'],
        editDialog: false,
        editedIndex: -1,
        imageFile: null,
        iconFile: null,
        image: null,
        icon: null,
        editedEntity: {
            id: null,
            name: '',
            active: false,
            args: null,
            description: '',
            icon: '',
            image: '',
            path: null,
            procs: null,
            tags: [],
        },
        defaultEntity: {
            id: null,
            name: '',
            active: false,
            args: null,
            description: '',
            icon: '',
            image: '',
            path: null,
            procs: null,
            tags: [],
        }
    }),

    computed: {
        searchAttributes() {
            return [
                { label: this.$t('generalAttributes.name'), value: 'Name' }
            ];
        },
        formTitle() {
            return this.editDialog ? (this.$t(`entities.${this.entity}.${this.editedIndex === -1 ? 'new' : 'edit'}Entity`)) : '';
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchEntities(append = `&size=${this.pagination.per_page}`) {
            try {
                if (!this.isAdmin) {
                    append += "&active=true";
                }
                this.loading = true;
                const response = await App.api.get(append);
                const data = response.data;
                this.entities = data.data.map(AppDTO => new App(AppDTO));
                this.pagination.current_page = data.metadata.page;
                this.pagination.per_page = data.metadata.size;
                this.pagination.total = data.metadata.count;
                this.pagination.last_page = Math.ceil(data.metadata.count / data.metadata.size);
                this.pagination.from = (data.metadata.page-1)*data.metadata.size + 1;
                this.pagination.to = this.pagination.from + data.metadata.size - 1 > this.pagination.total ? this.pagination.total : this.pagination.from + data.metadata.size - 1;
            } catch (e) {
                console.warn('Apps API failed.');
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async fetchArenaApps() {
            try {
                const response = await ArenaApp.api.get(this.$store.getters.arena.id, 'size=500');
                const data = response.data;
                this.arenaApps = data.data.map(ArenaAppDTO => new ArenaApp(ArenaAppDTO));
            } catch (e) {
                console.warn('Arena Apps API failed.');
                console.log(e);
            }
        },
        async save() {
            try {
                this.saving = true;

                if (!this.$refs.form.validate()) {
                    return;
                }

                if (this.imageFile) {
                    await FilesApi.upload(this.addingToArena ? 'arenas' : 'core', this.imageFile)
                        .then((response) => {
                            this.imageFile = null;
                            if (!response || response.status !== 200) {
                                this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                return;
                            }
                            this.image = response.data.link;
                        })
                        .catch(() => {
                            this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                        });
                }

                if (this.iconFile) {
                    await FilesApi.upload(this.addingToArena ? 'arenas' : 'core', this.iconFile)
                        .then((response) => {
                            this.iconFile = null;
                            if (!response || response.status !== 200) {
                                this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                return;
                            }
                            this.icon = response.data.link;
                        })
                        .catch(() => {
                            this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                        });
                }

                const data = {
                    name: this.editedEntity.name,
                    active: this.editedEntity.active ? 1 : 0,
                    image: this.image || this.editedEntity.image,
                    icon: this.icon || this.editedEntity.icon,
                    description: null,
                    tags: null,
                }

                if (this.editedEntity.description) {
                    data.description = this.editedEntity.description;
                }

                if (this.editedEntity.tags && this.editedEntity.tags.length) {
                    data.tags = this.editedEntity.tags;
                }

                if (this.addingToArena) {
                    data.description = this.editedEntity.description || null;
                    data.active = this.editedEntity.active;
                    data.tags = this.editedEntity.tags || null;
                    data.path = this.editedEntity.path || null;
                    data.procs = this.editedEntity.procs || null;
                    data.args = this.editedEntity.args || null;
                    data.display_order = this.getMaxDisplayOrderNumber() + 1;
                    data.global_id = this.editedEntity.id;

                    await ArenaApp.api.create(this.$store.getters.arena.id, data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.refresh();
                            }
                            this.showSnackbar('success', this.$t('entities.apps.notifications.addedToArena'));
                            this.closeEditDialog();
                        }).catch(error => {
                            this.axiosCatch(error);
                        });
                } else {
                    if (this.editedIndex > -1) {
                        await App.api.update(this.editedEntity.id, data)
                            .then(() => {
                                if (this.pagination.search.length) {
                                    this.pagination.search = '';
                                } else {
                                    this.fetchEntities();
                                }
                                this.showSnackbar('success', this.$t('entities.apps.notifications.update'));
                                this.closeEditDialog();
                            })
                            .catch(error => {
                                this.axiosCatch(error);
                            });
                    } else {
                        await App.api.create(data)
                            .then(() => {
                                if (this.pagination.search.length) {
                                    this.pagination.search = '';
                                } else {
                                    this.fetchEntities();
                                }
                                this.showSnackbar('success', this.$t('entities.apps.notifications.creation'));
                                this.closeEditDialog();
                            }).catch(error => {
                                this.axiosCatch(error);
                            });
                    }
                }
            } catch (error) {
                console.warn(`Apps Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        alreadyInArena(app) {
            return this.arenaApps.some(arenaApp => arenaApp.global_id === app.id);
        },
        openEditDialog(entity, addingToArena) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.editDialog = true;
            this.addingToArena = addingToArena;
        },
        closeEditDialog() {
            this.editDialog = false;
            this.addingToArena = false;
            this.editedEntity = {...this.defaultEntity};
            this.image = null;
            this.icon = null;
            this.editedIndex = -1;
        },
        refresh() {
            this.fetchEntities();
            if (this.$store.getters.arena) {
                this.fetchArenaApps();
            }
        },
        getMaxDisplayOrderNumber() {
            let maxDisplayOrder = 0;
              for (const app of this.arenaApps) {
                  if (app.display_order > maxDisplayOrder) {
                      maxDisplayOrder = app.display_order;
                  }
              }
              return maxDisplayOrder;
          }
    }
});

</script>
