<template>
    <v-toolbar-title class="ml-0">
        <v-row align="center">
            <v-col
                cols="auto"
                :class="{ 'toolbar-title-width': $vuetify.breakpoint.mdAndUp }"
            >
                <v-row align="center">
                    <v-col
                        cols="auto"
                        class="pr-0"
                    >
                        <div
                            @click.stop="$parent['$parent'].$emit('update:drawer', !drawer);"
                            v-bind:class="{ open: drawer }"
                            class="nav-ham d-inline-block white-background"
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </v-col>
                    <v-col
                        v-if="$vuetify.breakpoint.smAndUp"
                        cols="auto"
                        class="pl-0"
                    >
                            <span
                                class="white--text text-light"
                                style="font-size: 18px;"
                            >
                                {{ $t(currentRouteName) }}
                            </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                :class="{ 'col-auto' : $vuetify.breakpoint.smAndUp, 'col-9' : $vuetify.breakpoint.xsOnly }"
                class="pl-0"
            >
                <v-autocomplete
                    v-model="model"
                    :items="entities"
                    :loading="loading"
                    :search-input.sync="search"
                    :label="$t(`chooseArena.${entities.length > 0 ? 'title' : 'search'}`)"
                    :no-data-text="$t(`chooseArena.${entities.length > 0 ? 'noArenas' : 'typeInTheName'}`)"
                    :dense="$vuetify.breakpoint.xsOnly"
                    :disabled="loading"
                    item-text="name"
                    item-value="id"
                    return-object
                    hide-selected
                    hide-details
                    clearable
                    chips
                    solo
                >
                    <template v-slot:selection="{ attr, on, item, selected }">
                        <v-chip
                            v-bind="attr"
                            v-on="on"
                            :input-value="selected"
                            color="secondary"
                            class="white--text"
                        >
                            <v-icon
                                v-if="$vuetify.breakpoint.smAndUp"
                                left
                            >
                                location_on
                            </v-icon>
                            <span v-text="formatArenaName(item.name)"></span>
                        </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                        <v-list-item-avatar>
                            <v-img
                                :src="item.image"
                                :alt="item.name"
                            />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.name"></v-list-item-title>
                            <v-list-item-subtitle v-text="formatAddress(item.address)"></v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
    </v-toolbar-title>
</template>

<script>

import Vue from 'vue';
import Arena from '@/models/setup/Arena';

import convertCase from '@/mixins/convert-case';
import user from '@/mixins/crud/computed/user';
import isRole from '@/mixins/crud/computed/isRole';

export default Vue.extend({
    props: ['drawer', 'currentRouteName'],

    mixins: [
        convertCase,
        user,
        isRole,
    ],

    data: () => ({
        loading: false,
        entities: [],
        model: null,
        search: null,
        debounce: null,
    }),

    watch: {
        search() {
            if (this.search) {
                if (this.debounce) {
                    clearTimeout(this.debounce)
                }
                this.debounce = setTimeout(() => {
                    this.fetchEntities()
                }, 250)
            }
        },
        model(val) {
            let arenaName = '';
            if (!val) {
                this.$store.commit('setArena', null);
                this.$store.commit('setSelectedCashRegisterLabel', null);
            } else {
                arenaName = this.titleToKebabCase(val.name);
                if (!this.$store.getters.arena || this.$store.getters.arena.id !== val.id) {
                    const arena = {
                        id: val.id,
                        name: val.name,
                        timezone: val.timezone,
                        cashRegisters: val.cash_registers,
                        paymentMethods: val.payment_methods.map(function(item) {
                            return item.payment_method
                        }),
                        company: {
                            taxes: val.company.taxes,
                        },
                        currency: val.currency,
                        locale: val.locale,
                        coins_per_currency: val.coins_per_currency,
                        automaticallyOpenPrintUponInvoiceCreation: val.invoice_settings.automatically_open_print_upon_invoice_creation,
                    };
                    this.$store.commit('setArena', arena);
                    this.$store.commit('setSelectedCashRegisterLabel', null);
                    this.$store.commit('setCountry', val.address?.country_code.toLowerCase() | 'us');
                    localStorage.setItem('preferred_arena', this.titleToKebabCase(arena.name));
                }
            }
            this.pushToRoute(this.$router.currentRoute.name, arenaName);
        }
    },

    mounted() {
        const arena = this.$route.params.arena;
        if (arena) {
            this.fetchEntities(this.snakeToTitleCase(arena.replaceAll('-', '_')));
        }
    },

    methods: {
        async fetchEntities(arenaName = '') {
            try {
                this.loading = true;
                let search = `name=${this.search || arenaName}`;
                if (!this.isAdmin) {
                    search += `&employee_id=${this.user.id}`;
                }
                const response = await Arena.api.get(`${search}&include_fields=company&include_fields=address&include_fields=cash_registers&include_fields=payment_methods&include_fields=invoice_settings`);
                this.entities.push(...response.data.data.map((ArenaDTO) => {
                    const arena = new Arena(ArenaDTO);
                    if (arena.name.toLowerCase() === arenaName.toLowerCase()) {
                        this.model = arena;
                    }
                    return arena;
                }));
            } catch (e) {
                console.warn('Arenas API failed.');
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        pushToRoute(route, arenaName) {
            const params = {};
            if (arenaName.length) {
                params.arena = arenaName;
            }
            if (this.$router.currentRoute.params.arena !== params.arena) {
                this.$router.push({
                    name: route,
                    params: params
                });
            }
        },
        formatArenaName(name) {
            if (this.$vuetify.breakpoint.smAndUp) {
                return name;
            } else if (name.trim().indexOf(' ') !== -1) {
                return name.match(/\b(\w)/g).join("").toUpperCase();
            } else {
                return name.substring(0, 2).toUpperCase();
            }
        },
        formatAddress(address) {
            return address && address.address1 ? `${address.address1}${address.address2 ? `, ${address.address2}` : ''}${address.settlement ? `, ${address.settlement}` : ''}${address.postcode ? `, ${address.postcode}` : ''}` : '';
        }
    }
})
</script>

<style>

.nav-ham {
    position: relative;
    top: 6px;
    left: -6px;
    width: 40px;
    height: 45px;
    cursor: pointer;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.nav-ham span {
    display: block;
    position: absolute;
    background: #fff;
    height: 2px;
    left: 10px;
    width: 55%;
    border-radius: 9px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.nav-ham span:nth-child(1) {
    top: 15px;
}

.nav-ham span:nth-child(2) {
    top: 20px;
}

.nav-ham span:nth-child(3) {
    top: 25px;
}

.nav-ham.open span:nth-child(1) {
    width: 42%;
}

.nav-ham.open span:nth-child(3) {
    width: 42%;
}

.toolbar-title-width {
    width: 275px !important;
}

</style>