

import Vue from 'vue';
import AuthApi from '@/services/auth/AuthApi';
import User from '@/models/users/User';
import UserSetting from '@/models/users/UserSetting';

import required from '@/mixins/rules/required';
import updateTheme from '@/mixins/update-theme';
import updateLanguage from '@/mixins/update-language';
import { app } from '@/main';

export default Vue.extend({
    props: ['loggedIn'],

    mixins: [
        required
    ],

    data: () => ({
        loggingIn: false,
        fetchingUser: false,
        showPassword: false,
        invalidCredentials: false,
        insufficientPermissions: false,
        unexpectedError: null,
        inactive: false,
        banned: false,
        credential: '',
        password: '',
    }),

    methods: {
        async login() {
            this.invalidCredentials = false;
            this.insufficientPermissions = false;
            this.unexpectedError = null;
            this.inactive = false;
            this.banned = false;

            const formValidation = (this.$refs.form as Vue & { validate: () => boolean }).validate();
            if (!formValidation) {
                return;
            }

            try {
                this.loggingIn = true;
                await AuthApi.login(this.credential, this.password)
                    .then(async (resp) => {

                        const accessToken = resp.data.access_token;
                        const refreshToken = resp.data.refresh_token;
                        const now = new Date();
                        const expiryDate = new Date(now.getTime() + resp.data.expires_in * 1000);

                        localStorage.setItem('access_token', accessToken);
                        localStorage.setItem('refresh_token', refreshToken);
                        localStorage.setItem('expiry_date', btoa(expiryDate.toString()));

                        this.$store.commit('setAuthenticated', true);

                        this.fetchingUser = true;
                        await this.fetchUser(this.credential);
                    }).catch(error => {
                        if (!error.response) {
                            this.unexpectedError = error;
                        } else {
                            if (error.response.status === 401) {
                                this.invalidCredentials = true;
                            } else {
                                if (error.response.data && error.response.data.message) {
                                    this.unexpectedError = error.response.data.message;
                                } else {
                                    this.unexpectedError = error.response;
                                }
                            }
                        }
                    });
            } catch (error) {
                console.warn('Login API failed');
            } finally {
                this.loggingIn = false;
            }
        },
        async fetchUser(username: string) {
            try {
                await User.api.findByUsername(username)
                    .then(async (response) => {
                        let user = response.data;

                        if (user.banned) {
                            this.banned = true;
                            return;
                        }
                        
                        if (!user.active) {
                            this.inactive = true;
                            return;
                        } 

                        const hasPermissions = user.roles.some(function(role: string) {
                            return ['admin', 'employee', 'manager'].includes(role);
                        });

                        if (!hasPermissions) {
                            this.insufficientPermissions = true;
                            return;
                        }

                        user = {
                            id: user.id,
                            credential: this.credential,
                            initials: user.username.substring(0, 2),
                            name: user.identity ? user.identity.name : null,
                            email: user.email ? user.email.address : null,
                            username: user.username,
                            pidn: user.identity ? user.identity.pidn : null,
                            roles: user.roles,
                            avatar: user.avatar,
                            keycloak_id: user.keycloak_id,
                            settings: await this.fetchUserDashboardSettings(user.id),
                        }

                        localStorage.setItem('user', btoa(unescape(encodeURIComponent(JSON.stringify(user)))));

                        let params = {};

                        const preferredArena = localStorage.getItem('preferred_arena');

                        if (preferredArena) {
                            params = {
                                arena: preferredArena
                            }
                        }

                        const isAdminOrManager = user.roles.some((r: string) => ['admin', 'manager'].includes(r))
                        updateTheme(user.settings.theme, app);
                        updateLanguage(user.settings.language);
                        await this.$router.push({
                            name: `navigationDrawer.main.${isAdminOrManager ? 'admin' : 'employee'}Dashboard`,
                            params: params
                        });
                    }).catch(error => {
                        if (!error.response) {
                            this.unexpectedError = error;
                        } else {
                            if (error.response.data && error.response.data.message) {
                                this.unexpectedError = error.response.data.message;
                            } else {
                                this.unexpectedError = error.response;
                            }
                        }
                    });
            } catch (e) {
                console.warn(`User API failed.`);
                console.log(e);
                localStorage.clear();
                this.$store.commit('setAuthenticated', false);
            } finally {
                this.fetchingUser = false;
            }
        },
        async fetchUserDashboardSettings(id: bigint) {
            const defaultSettings = {
                theme: process.env.VUE_APP_THEME || 'Classic',
                language: process.env.VUE_APP_I18N_LOCALE || 'en',
            };
            try {
                const response = await User.api.getSettingsByNamespace(id, 'dashboard');
                response.data.map((setting: UserSetting) => {
                    if (setting.key === 'theme') {
                        defaultSettings.theme = setting.value;
                    }
                    if (setting.key === 'language') {
                        defaultSettings.language = setting.value;
                    }
                });
            } catch (e) {
                console.warn(`User Settings API failed.`);
                console.log(e);
            }
            return defaultSettings;
        },
    },
})

