import store from "../store";

const formatDate = {
  methods: {
    formatDate(
      date: Date,
      useSeconds = true,
      toLocale = false,
      dateOnly = false
    ): string {
      if (toLocale && store.getters.arena) {
        return dateOnly
          ? date.toLocaleDateString(store.getters.arena.locale)
          : date.toLocaleString(store.getters.arena.locale, {
              timeZone: store.getters.arena.timezone,
            });
      }

      let formatted =
        date.getFullYear() +
        "-" +
        ("00" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("00" + date.getDate()).slice(-2) +
        " " +
        ("00" + date.getHours()).slice(-2) +
        ":" +
        ("00" + date.getMinutes()).slice(-2);

      if (useSeconds) {
        formatted += ":" + ("00" + date.getSeconds()).slice(-2);
      }

      return formatted;
    },
  },
};

export default formatDate;
